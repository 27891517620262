import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react"
import { courseService } from "../services";
import StarRatings from "react-star-ratings";
import { alertActions } from "../actions";

const CourseRating = ({ course, onClose }) => {

    let [star, setStar] = useState(null)
    let [comment, setComment] = useState('')

    useEffect(() => {
        courseService.getCourseFeedback(course.id)
            .then(res => {
                if (res.isSuccess && res.data) {
                    setStar(res.data.star)
                    setComment(res.data.comment)
                }
            });
    }, [])

    const onSubmit = () => {
        courseService.courseFeedback(course.id, star, comment)
            .then(res => {
                if (res.isSuccess) {
                    alertActions.success("Gửi đánh giá thành công.");
                }
            })
            .finally(() => {
                onClose?.call()
            });
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <div className='w-100'>
                    <div className='d-flex justify-content-center'>
                        <StarRatings
                            rating={star ?? 0}
                            starRatedColor="rgb(230, 67, 47)"
                            changeRating={(newRating) => { setStar(newRating) }}
                            numberOfStars={5}
                            name='rating'
                        />
                    </div>
                    <textarea className='form-control mt-3 h-100px'
                        type='texts'
                        maxLength="250"
                        placeholder="Cảm nghĩ của bạn"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <button className='btn btn-success'
                        disabled={star == null}
                        onClick={onSubmit}>
                        Gửi đánh giá
                    </button>
                </div>
            </div>
        </div>
    )
}

export { CourseRating }