import { hot } from 'react-hot-loader';
import { Route, Switch } from "react-router";
import AdminApp from "./admin/AdminApp";
import App from "./App";
import { Loading } from "./shared";
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      },
    },
  })

const Main = hot(module)((props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GoogleClientId}>
                <Loading />
                <Switch>
                    <Route path='/admin' component={AdminApp} />
                    <Route path='/' component={App} />
                </Switch>
                <ToastContainer />
            </GoogleOAuthProvider>
        </QueryClientProvider>
    );
})

export default hot(module)(Main);