import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { bannerService, userService } from "../services";
import { connect, useDispatch, useSelector } from 'react-redux';
import { alertActions, userActions } from '../actions';
import SimpleReactValidator from 'simple-react-validator';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { history } from '../helpers';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { GoogleLoginButton } from './GoogleLoginButton';
import { useQuery } from 'react-query';
import { ContactBlock } from '../home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import { addPopupAction } from '../reducers/popup.reducer';

const Register = () => {

   const authentication = useSelector(s => s.authentication)
   const loggedIn = authentication?.loggedIn ?? false

   useEffect(() => {
      if (loggedIn) {
         history.push('/my-courses')
      }
   }, [loggedIn])

   return (
      <section className="w-100">
         {/* <div className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
            <div className="container">
               <div className="row mt-5">
                  <div className="col-md-12 col-lg-8 text-left px-5">
                     <h1 className="text-uppercase mb-1 h1 fw-700">Đăng nhập</h1>
                     <div className="h3 text-uppercase fw-400" >Trang chủ / Đăng nhập</div>
                  </div>
               </div>
            </div>
            <div className='w-100'>
               <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
            </div>
         </div> */}
         <section className="container my-5">

            <div className="w-100 row flex-row-reverse contact-form-wrapper mt-3 contact-form-wrapper">
               <div className="col-md-12 col-lg-6">
                  <RegisterForm />
               </div>
               <div className="col-md-12 col-lg-6">
                  <ContactBlock />
               </div>

               <div className='col-12 text-center mt-5'>
                  <div className='h3'>Bạn đã có tài khoản</div>
                  <div className=''><Link to='/login' className='text-dark-orange h3 fw-600 mt-3'>Đăng nhập ngay</Link></div>
               </div>
            </div>
         </section>
      </section>
   )
}

const RegisterForm = () => {
   const dispatch = useDispatch()
   const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch, reset } = useForm({
      defaultValues: {
         email: '',
         password: '',
         displayName: '',
      }
   });

   const { data: bannersResult, isFetching, isFetched } = useQuery(['banners'], () => { return bannerService.getAll() }, { staleTime: Infinity })
   let banners = bannersResult?.data ?? [];
   banners = banners.filter(r => r.active && r.type == 'popup' && r.event == 'LOGIN')
   let hasBanner = banners.length > 0

   const onSubmit = (values) => {
      let model = {...values}

      userActions.signup(model.email, model.displayName, model.password, () => {
         if (hasBanner) {
            dispatch(addPopupAction(banners))
         }
      })
   }

   const loginFacebookClick = (event) => {
   }

   const responseFacebook = (response) => {
      if (response.accessToken) {
         userActions.loginFacebook(response.accessToken);
      } else {
         // alertActions.error("Đăng nhập tài khoản Facebook không thành công");
      }
   }

   const responseGoogle = (response) => {
      userActions.loginGoogle(response.tokenId);
   }

   const failedGoogle = (response) => {
      // alertActions.error("Đăng nhập tài khoản Google không thành công");
   }

   const onLoginFacebookClicked = () => {
      window.FB.login(response => {
         responseFacebook(response.authResponse)
      }, { scope: 'public_profile,email' })
   }

   return (
      <div>
         <div className="row">
            <div className='col-12 px-0'>
               <div className="h2">Đăng ký</div>
               <form className="pb-3" onSubmit={(e) => e.preventDefault()} autoComplete='off'>

                  <input className={`form-control form-control-lg rounded bg-transparent border-orange text-orange mt-3 ${errors.userName ? 'is-invalid' : ''}`}
                     type="text"
                     autoComplete='displayName'
                     autoCorrect="off"
                     placeholder="Họ và tên"
                     {...register('displayName', { required: true })} />

                  <input className={`form-control form-control-lg rounded bg-transparent border-orange text-orange mt-3 ${errors.userName ? 'is-invalid' : ''}`}
                     type="text"
                     autoComplete='email'
                     autoCorrect="off"
                     placeholder="Email"
                     {...register('email', { required: true })} />

                  <PasswordInput register={register} errors={errors} />

                  {/* <input className={`form-control form-control-lg rounded bg-transparent border-orange text-orange mt-3 ${errors.password ? 'is-invalid' : ''}`}
                     type="password"
                     autoComplete='new-password'
                     autoCorrect="off"
                     placeholder="Mật khẩu"
                     {...register('password', { required: true })} /> */}

                  <button type='submit' className="btn btn-success bg-dark-orange w-100 mt-5 h4 text-uppercase py-2" onClick={handleSubmit(onSubmit)}>Đăng ký</button>
                  <div className="w-100 mt-2">
                     <GoogleLoginButton />
                  </div>

                  <div className="w-100 mt-2">
                     <button className="btn btn-primary h4 w-100 text-uppercase py-2" onClick={onLoginFacebookClicked}>
                        <span className="ml-2">Tiếp tục với Facebook</span>
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div >
   )
}

const PasswordInput = ({ register, errors }) => {
   let [isShow, setIsShow] = useState(false);
   return (
      <div className="input-group mt-3 rounded bg-transparent border border-orange text-orange">
         <input className={`form-control form-control-lg bg-transparent border-0 ${errors.password ? 'is-invalid' : ''}`}
            type={isShow ? 'text' : 'password'}
            maxLength="50"
            autoComplete='new-password'
            autoCorrect="off"
            placeholder="Mật khẩu"
            {...register('password', { required: true })} />
         <span className="input-group-append">
            <button className="btn" onClick={() => setIsShow(!isShow)}>
               {isShow ?
                  <FontAwesomeIcon icon={allIcon.faEyeSlash} /> :
                  <FontAwesomeIcon icon={allIcon.faEye} />
               }
            </button>
         </span>
      </div>
   )
}

export { Register };