import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import * as allIcon from '@fortawesome/free-solid-svg-icons';

const CourseRequire = ({ course }) => {
    let { contents } = course;
    return (
        <div className="CourseDetail_topicList mt-4">
            {(contents && contents.filter((item) => { return item.type == 'requirement'; }).length > 0) &&
                <>
                    <div className='h4 fw-700'>Thông tin khác</div>
                    <section className="row">
                        <section className="col-md-12 col-lg-12">
                            <ul className="list-group">
                                {contents.filter((item) => { return item.type == 'requirement'; }).map(item =>
                                    <li key={item.id} className='list-group-item border-0 pl-2'>
                                        <FontAwesomeIcon icon={allIcon.faCheck} className="fa-w-16 CourseDetail_icon" />
                                        <span className='pl-2'>{item.content}</span>
                                    </li>
                                )}
                            </ul>
                        </section>
                    </section>
                </>
            }
        </div>
    )
}

export { CourseRequire };