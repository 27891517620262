import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { CourseGridItem } from '../courses';
import { courseService } from "../services/index";
import { HomeFeatured, HomeBanner, HomeCallToAction, HomeShortFeature, HomeIntro } from "./index";
import { HomeReview } from './HomeReview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import * as allIconR from "@fortawesome/free-regular-svg-icons";
import { KOCImage } from '../shared/KocImage';
import { useQuery } from 'react-query';

const Home = () => {
    return (
        <div className="">
            <HomeBanner />
            {/* <HomeIntro /> */}

            <HomeFeatured />
            <HomeShortFeature />

            <section>
                <section className="container">
                    <div className="row">
                        <div className="col-12 mt-4 text-center">
                            <div className="h2 fw-700 text-uppercase">VÌ SAO NÊN HỌC CÙNG CHÚNG TÔI</div>
                        </div>
                    </div>
                    <div className="row mt-4 justify-content-between align-items-stretch">
                        <div className='col-12 col-lg-3 bg-light-pink py-4 rounded-10px mt-2'>
                            <div className='ml-3 w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-2'>
                                <FontAwesomeIcon icon={allIcon.faSearch} size='2x' />
                            </div>
                            <div className='h4 fw-600 mt-4'>Nội dung phong phú</div>
                            <div className='mt-3 h6'>
                                Hơn 400 khóa học với hàng chục lĩnh vực giúp bạn dễ dàng tìm được nội dung phù hợp với mình nhất.
                            </div>
                        </div>
                        <div className='col-lg-1 d-none d-lg-flex align-items-center'>
                            <img src='/assets/images/arrow-ic.png' className='w-100' />
                        </div>
                        <div className='col-12 col-lg-3 bg-light-pink py-4 rounded-10px mt-2'>
                            <div className='ml-3 w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-2'>
                                <FontAwesomeIcon icon={allIcon.faEdit} size='2x' />
                            </div>
                            <div className='h4 fw-600 mt-4'>Giảng viên chất lượng</div>
                            <div className='mt-3 h6'>
                                Những bài học chất lượng được dẫn dắt bởi các giảng viên, người truyền cảm hứng đứng đầu Việt Nam.
                            </div>
                        </div>
                        <div className='col-lg-1 d-none d-lg-flex align-items-center'>
                            <img src='/assets/images/arrow-ic.png' className='w-100' />
                        </div>
                        <div className='col-12 col-lg-3 bg-light-pink py-4 rounded-10px mt-2'>
                            <div className='ml-3 w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-1'>
                                <FontAwesomeIcon icon={allIcon.faUsers} size='2x' />
                            </div>
                            <div className='h4 fw-600 mt-4'>Học mọi lúc mọi nơi</div>
                            <div className='mt-3 h6'>
                                Hệ thống online sẽ hỗ trợ bạn tham gia học ở bất cứ đâu và bất cứ khi nào.
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <TopLectures />
            <HomeReview />
            <HomeCallToAction />
        </div >
    )
}

const TopLectures = () => {

    const { data: apiResult, isSuccess } = useQuery("courses-authors", () => courseService.getAuthors(), { staleTime: 600000 });
    const authors = apiResult?.data ?? []

    const lecturers = [
        {
            name: 'Trần Việt Quân',
            image: 'https://9talk.edu.vn/assets/images/Trần Việt Quân.jpg',
            job: 'Diễn giả'
        },
        {
            name: 'Trần Việt Quân',
            image: 'https://9talk.edu.vn/assets/images/Trần Việt Quân.jpg',
            job: 'Diễn giả'
        },
        {
            name: 'Trần Việt Quân',
            image: 'https://9talk.edu.vn/assets/images/Trần Việt Quân.jpg',
            job: 'Diễn giả'
        },
        {
            name: 'Trần Việt Quân',
            image: 'https://9talk.edu.vn/assets/images/Trần Việt Quân.jpg',
            job: 'Diễn giả'
        },
    ]

    return (
        <section>
            <section className="container py-5">
                <div className="row">
                    <div className="col-12 mt-4 ">
                        <div className="h2 fw-700 text-uppercase">Giảng viên hàng đầu</div>
                    </div>
                </div>
                <div className="row mt-3">
                    {authors.filter(r => r.featured).map((lecturer, index) =>
                    (
                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 px-3 py-1' key={index} >
                            <LecturerItem lecturer={lecturer} />
                        </div>
                    ))}
                </div>
            </section>
        </section>
    )
}

const LecturerItem = ({ lecturer }) => {
    return (
        <div className='border rounded-15px h-100 w-100 px-3 py-2 position-relative hover-shadow-light-orange'>
            <div className='position-relative' style={{ paddingTop: '100%' }}>
                <div className="position-absolute top-0 left-0 w-100 h-100">
                    <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' src={lecturer.avatar} />
                </div>
            </div>
            <div className='text-center h4 fw-600 mt-2'>
                <Link className='stretched-link' to={`/teacher/${lecturer.id}/${lecturer.slug}`}>{lecturer.name}</Link>
            </div>
            <div className='text-center h6'>
                {lecturer.introduce}
            </div>
            <div className='d-flex justify-content-around px-2 pb-2'>
                <a target='_blank' href={lecturer.facebook}><img src='/assets/images/facebook-ic.png' className='w-40px' /></a>
                <a target='_blank' href={lecturer.instagram}><img src='/assets/images/instagram-ic.png' className='w-40px' /></a>
                <a target='_blank' href={lecturer.twitter}><img src='/assets/images/twitter-ic.png' className='w-40px' /></a>
            </div>
        </div>
    )
}

export { Home };