import React, { Component, useEffect, useState } from 'react';
import { Link, Switch, Route } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import { UrlHelpers, history } from '../helpers';
import { userActions } from '../actions';
import { MoreMenu, MoreMenuMobile } from './';
import { Avatar } from "../shared";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { logoutAction } from '../reducers/authentication.reducer';
let logo = "/assets/images/logo(177x50).png";

const NavigationBar = () => {
    return (
        <Switch>
            <Route path='/learning'>
            </Route>
            <NavigationBarContent />
        </Switch>
    )
}

const NavigationBarContent = () => {
    var dispatch = useDispatch()
    let authentication = useSelector(r => r.authentication)
    let { loggedIn } = authentication

    let navItems = []

    if (loggedIn)
        navItems = [
            { link: '/', text: 'Trang chủ' },
            // { link: '/contact', text: 'liên hệ' },
            // { link: '/live-schedules', text: 'Live' },
            { link: '/my-courses', text: 'khóa học của tôi' },
            { link: '/courses-v2', text: 'Sản phẩm' },
            // { link: '/courses-zoom', text: 'khóa học Zoom' },
            { link: '/teachers', text: 'Huấn luyện viên' },
            { link: '/books', text: 'Blog' },
            // { link: '/workshops', text: 'workshop' },
            // { link: 'https://9talk.edu.vn', text: '9talk', external: true },
            // { link: '/blog', text: 'blog' },
            { link: '/contact', text: 'Liên hệ' },
        ]
    else {
        navItems = [
            { link: '/', text: 'Trang chủ' },
            // { link: '/contact', text: 'liên hệ' },
            // { link: '/live-schedules', text: 'Live' },
            { link: '/courses', text: 'khóa học' },
            { link: '/courses-v2', text: 'Sản phẩm' },
            // { link: '/courses-zoom', text: 'khóa học Zoom' },
            { link: '/teachers', text: 'Huấn luyện viên' },
            { link: '/books', text: 'Blog' },
            // { link: '/workshops', text: 'workshop' },
            // { link: 'https://9talk.edu.vn', text: '9talk', external: true },
            // { link: '/blog', text: 'blog' },
            { link: '/contact', text: 'Liên hệ' },
        ]
    }


    return (
        <div className='app-nav w-100 bg-light-pink'>
            <div className='position-relative h-100'>
                <div className='position-absolute top-0 z-index-500 w-100 h-100'>
                    <div className='container h-100 pt-4'>
                        <div className='d-flex align-items-center justify-content-between wrapper border border-orange py-2 rounded-lg'>
                            <div className='nav-logo-wrap ml-2'>
                                <Link to={'/'}><img src={logo} className='d-none d-sm-block h-50px' /></Link>
                                <Link to={'/'}><img src='/assets/images/logo(70x50).png' className='d-block d-sm-none h-50px' /></Link>
                            </div>
                            <div className='nar-right float-right font-weight-bold mr-2 d-flex'>
                                <div className='d-lg-flex d-none'>
                                    {navItems.map((item, index) => (
                                        <NavItem {...item} key={index} />
                                    ))}
                                </div>
                                <div className='d-flex d-lg-none'>
                                    <div className='nav-item text-uppercase px-3 d-flex align-items-center'>
                                        <CDropdown
                                            className=""
                                            direction="down"
                                        >
                                            <CDropdownToggle className="c-header-nav-link" caret={false}>
                                                <FontAwesomeIcon className="fa-w-14 NavBar_icon lms-menu-icon text-orange" icon={allIcon.faBars} size='lg' />
                                            </CDropdownToggle>
                                            <CDropdownMenu placement="bottom-end">
                                                {navItems.filter(r => r.link != '/').map((item, index) => (
                                                    <CDropdownItem key={item.link} to={item.link} target={item.external ? '_blank' : ''} className="text-dark dropdown-item">
                                                        {/* <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faUser} /> */}
                                                        <span className='text-capitalize font-weight-normal'>{item.text}</span>
                                                    </CDropdownItem>
                                                ))}

                                            </CDropdownMenu>
                                        </CDropdown>
                                    </div>
                                </div>

                                <div className='nav-item px-1 d-flex align-items-center'>
                                    {loggedIn ?
                                        <>
                                            <UserNav />
                                        </> :
                                        <div className='btn btn-success text-uppercase px-2' onClick={() => { history.login() }}>
                                            <span className='font-weight-bold h5 d-sm-inline'>VÀO HỌC</span>
                                            {/* <span className='font-weight-bold h5 d-inline d-sm-none'>
                                                <FontAwesomeIcon icon={allIcon.faSignInAlt} />
                                            </span> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavItem = ({ link, text, external }) => {

    return (
        <div className='nav-item text-uppercase px-3 d-flex align-items-center'>
            <Link to={{ pathname: link }} target={external ? '_blank' : ''}><span className='text-orange text-hover-success'>{text}</span></Link>
        </div>
    )
}

const UserNav = () => {
    const dispatch = useDispatch()
    let authentication = useSelector(state => state.authentication)
    const { userInfomation } = authentication;
    let isAdmin = authentication.loggedIn && authentication.userInfomation?.roles?.length
    const getFirstName = () => {
        let displayName = userInfomation?.displayName
        if (displayName) {
            var arr = displayName.split(' ');
            if (arr.length > 0) {
                var firstName = arr[arr.length - 1]
                return firstName
            }
        }
        return displayName
    }
    return (
        <CDropdown
            className=""
            direction="down"

        >
            <CDropdownToggle className="c-header-nav-link" caret={false}>
                <Avatar url={userInfomation?.avatar} className="w-30px h-30px rounded-circle mr-1" />
                <span className="h6 mr-2 text-orange fw-700">{getFirstName()}</span>
                <FontAwesomeIcon className="fa-w-14 NavBar_icon lms-menu-icon text-orange" icon={allIcon.faCaretDown} />
            </CDropdownToggle>
            <CDropdownMenu placement="bottom-end">
                {isAdmin == true &&
                    <CDropdownItem to='/admin' className="text-dark dropdown-item">
                        <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faTools} />
                        <span className=' text-capitalize font-weight-normal'>Admin</span>
                    </CDropdownItem>
                }
                <CDropdownItem to='/user/info' className="text-dark dropdown-item">
                    <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faUser} />
                    <span className='text-capitalize font-weight-normal'>Thông tin cá nhân</span>
                </CDropdownItem>
                <CDropdownItem to='/my-courses' className="text-dark dropdown-item">
                    <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faBookOpen} />
                    <span className='text-capitalize font-weight-normal'>Khóa học của tôi</span>
                </CDropdownItem>
                {/* <CDropdownItem to='/user/favorist' className="text-dark dropdown-item">
                    <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faHeart} />
                    <span className='text-capitalize font-weight-normal'>Khóa học yêu thích</span>
                </CDropdownItem> */}
                <CDropdownItem to='/user/settings' className="text-dark dropdown-item">
                    <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faCog} />
                    <span className='text-capitalize font-weight-normal'>Cài đặt chung</span>
                </CDropdownItem>
                <CDropdownItem onClick={() => { dispatch(logoutAction()); history.login() }} className="text-dark">
                    <FontAwesomeIcon className="fa-w-16 MoreMenu_icon mr-2 font-weight-normal" icon={allIcon.faSignOutAlt} />
                    <span className='text-capitalize font-weight-normal'>Đăng xuất</span>
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    )
}

const CartMenu = () => {
    let { courses, workshops } = useSelector(r => r.myCart)
    const courseCount = courses?.length ?? 0
    const workshopCount = workshops?.length ?? 0
    let hasItem = courseCount + workshopCount > 0
    return (
        <div className={`nav-item text-uppercase px-3 ${!hasItem ? 'd-none' : 'd-flex'} align-items-center position-relative`}>
            <Link to={UrlHelpers.courseCheckout} className="text-white text-hover-success">
                <FontAwesomeIcon icon={allIcon.faShoppingCart} />
                {hasItem &&
                    <span className='badge badge-danger badge-pill position-absolute top-0'><small>{courseCount + workshopCount}</small></span>
                }
            </Link>
        </div>
    )
}

export { NavigationBar };