
import { useGoogleLogin } from '@react-oauth/google';
import { userActions } from '../actions';

const GoogleLoginButton = () => {

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            userActions.loginGoogle(tokenResponse.access_token);
        },
        onError: () => {
            console.log('Login Failed');
        }
    });

    return (
        <button className="btn btn-success py-2 w-100 px-0 text-uppercase" onClick={login}>
            <span className="ml-2 h5">Tiếp tục với Google</span>
        </button>
    )
}

export { GoogleLoginButton }