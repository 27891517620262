import { alertConstants } from '../constants';
import { store } from '../helpers';
import { toast } from 'react-toastify';

export const alertActions = {
    success,
    error,
    info,
    clear,
    successUpdate,
    failUpdate,
    successDelete,
};

function success(message) {
    toast.success(message)
}

function error(message) {
    toast.error(message)
}

function info(message) {
    toast.info(message)
}

function clear() {
    toast.dismiss()
}


function successUpdate() {
    return success('Cập nhật thành công');
}

function failUpdate() {
    return error('Cập nhật không thành công');
}

function successDelete() {
    return success('Xoá thành công');
}