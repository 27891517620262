import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import { Avatar, NoData } from "../shared";
import { CourseGridItem, CourseGridItem2 } from './CourseGridItem';
import { bannerService, courseService } from "../services/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { connect, useDispatch, useSelector } from 'react-redux';
import { history, NumberHelpers, UrlHelpers } from '../helpers';
import { CModal, CModalBody, CModalHeader, CNav, CNavItem } from '@coreui/react';
import { useQuery } from 'react-query';
import { addPopupAction } from '../reducers/popup.reducer';
import { userActions } from '../actions';
import { KOCImage } from '../shared/KocImage';
import classNames from 'classnames';
import moment from 'moment';

const MyCerts = () => {
    window.gtag('event', 'page_view', {
        page_location: window.location
    })

    const { data: userresult } = useQuery(['user-certificates'], () => courseService.getUserCertificates())
    let userCertificates = userresult?.data ?? []

    useEffect(() => {
        userActions.getMyCourses();
    }, []);

    let authentication = useSelector(r => r.authentication);
    let { userInfomation } = authentication;

    useEffect(() => {
        if (!authentication.loggedIn) {
            history.login()
        }
    }, [authentication]);

    return (
        <section className="w-100">
            {/* <section className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
                <section className="container">
                    <section className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Khóa học</h1>
                            <div className="h3 text-uppercase fw-400" >Trang chủ / Khóa học</div>
                        </div>
                    </section>
                </section>
                <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div>
            </section> */}
            <section className="container py-5">
                <div className='row'>
                    <div className='col-12'>
                        <CNav variant="tabs" role="tablist" className='border-0 bg-light-pink rounded-10px py-2 pl-3'>
                            <CNavItem className='bg-transparent py-2'>
                                <Link replace
                                    className={classNames(`px-2 py-2 rounded-8px text-decoration-none text-black border border-orange`)}
                                    to='/my-courses'>
                                    <span className=''>KHÓA HỌC CỦA TÔI</span>
                                </Link>
                            </CNavItem>

                            <CNavItem className='bg-transparent py-2'>
                                <Link replace
                                    className={classNames(`ml-2 px-2 py-2 rounded-8px text-decoration-none bg-orange text-white`)}
                                    to='/my-certs'>
                                    <span className=''>CHỨNG CHỈ CỦA TÔI</span>
                                </Link>
                            </CNavItem>
                            <CNavItem className='bg-transparent py-2'>
                                <Link replace
                                    className={classNames(`ml-2 px-2 py-2 rounded-8px text-decoration-none text-black border border-orange`)}
                                    to='/user/rank'>
                                    <span className=''>XẾP HẠNG</span>
                                </Link>
                            </CNavItem>
                        </CNav>
                    </div>
                </div>

                {/* <div className='row'>
                    <div className='col-12'>
                        <div className="text-uppercase mb-3 h2 fw-500">Khóa học của tôi</div>
                    </div>
                </div> */}

                <section className="row mt-4">
                    {userCertificates.length == 0 &&
                        <div className='col-12'>
                            <NoData message='Bạn chưa có chứng chỉ. Bạn vui lòng quay lại sau.' />
                            <div className='d-flex justify-content-center'><Link to='/my-courses' className='btn btn-success mt-2'>Tiếp tục học tập</Link></div>
                        </div>
                    }

                    {userCertificates.map((item, index) =>
                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 py-2">
                            <MyCourseItem item={item} />
                        </div>
                    )}
                </section>
            </section>
        </section>
    )
}

const MyCourseItem = ({ item }) => {

    return (
        <div className='w-100 h-100 px-3 py-3 border border-orange rounded-15px'>
            <div className='course-wrapper h-100 overflow-hidden d-flex flex-column'>
                <div className='course-image position-relative' style={{ paddingTop: '50%' }}>
                    <div className="position-absolute top-0 left-0 w-100 h-100">
                        <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' src={item.certificate.icon} />
                    </div>
                </div>
                <div className="course-info pt-2 pb-2 flex-grow-1">
                    <div className="course-title text-o-ellipsis text-line-2 mt-2">
                        <div to={UrlHelpers.courseDetailPath} className='text-black' title={item.certificate.name}>
                            <span className="h4">{item.certificate.name}</span>
                        </div>
                        <div className='pt-2 h6 text-warning'>{moment(item.createdTime, 'x').local().format("yyyy/MM/DD HH:mm:ss")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { MyCerts }