import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import { NoData } from "../shared";
import { CourseGridItem, CourseGridItem2 } from './CourseGridItem';
import { courseService } from "../services/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import { history } from '../helpers';
import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import { useQuery } from 'react-query';
import { KOCImage } from '../shared/KocImage';
import { useSearchParams } from '../shared/useSearchParams';

const Teachers = () => {
    let searchParams = useSearchParams()
    let key = searchParams.get('key') ?? ''

    const { data: authorsResult, isSuccess } = useQuery("courses-authors", () => courseService.getAuthors(), { staleTime: 600000 });
    const authors = authorsResult?.data ?? []


    const filteredAuthors = authors.filter(r => r.name.toUpperCase().includes(key.toUpperCase()))

    return (
        <section className="w-100">
            {/* <section className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
                <section className="container">
                    <section className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Giảng viên</h1>
                            <div className="h3 text-uppercase fw-400" >Trang chủ / Giảng viên</div>
                        </div>
                    </section>
                </section>
                <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div>
            </section> */}
            <section className="container py-5">
                <section className="row">
                    <section className="col-12 p-0">
                        <div className="row mb-3">
                            <div className='col-12 d-flex justify-content-between'>
                                <div className='col-lg-6 p-0'>
                                    <Search />
                                </div>
                                {/* <div className="">
                                    <label className="select" htmlFor="slct">
                                        <select value={sortType} onChange={sortTypeChanged} className='form-control'>
                                            <option value="default">Sắp xếp</option>
                                            <option value="name">A-Z</option>
                                            <option value="newest">Mới nhất</option>
                                            <option value="price-asc">Giá thấp - cao</option>
                                            <option value="price-desc">Giá cao - thấp</option>
                                        </select>
                                    </label>
                                </div> */}
                            </div>
                        </div>
                        <section className="row">
                            {filteredAuthors.map(item =>
                                <div key={item.id} className="col-12 col-sm-6 col-md-4 col-lg-3 px-3 py-2">
                                    {/* <CourseGridItem2 data={item} /> */}
                                    <TeacherItem teacher={item} />
                                </div>
                            )}
                        </section>
                    </section>
                </section>
            </section>
        </section>
    )
}

const TeacherItem = ({ teacher }) => {
    return (
        <div className='border rounded-15px h-100 w-100 px-3 py-2 position-relative hover-shadow-light-orange'>
            <div className='position-relative' style={{ paddingTop: '100%' }}>
                <div className="position-absolute top-0 left-0 w-100 h-100">
                    <KOCImage className='w-100 h-100 objectfit-cover rounded-10px border' src={teacher.avatar} />
                </div>
            </div>
            <div className='text-center h4 fw-600 mt-2'>
                <Link className='stretched-link' to={`/teacher/${teacher.id}/${teacher.slug}`}>{teacher.name}</Link>
            </div>
            <div className='text-center h6'>
                {teacher.introduce}
            </div>
            <div className='d-flex justify-content-around px-2 pb-2'>
                <a target='_blank' href={teacher.facebook}><img src='/assets/images/facebook-ic.png' className='w-40px' /></a>
                <a target='_blank' href={teacher.instagram}><img src='/assets/images/instagram-ic.png' className='w-40px' /></a>
                <a target='_blank' href={teacher.twitter}><img src='/assets/images/twitter-ic.png' className='w-40px' /></a>
            </div>
        </div>
    )
}

const Search = ({ }) => {

    let { filterType, id: catId, name } = useParams()
    let [keyword, setKeyword] = useState("")

    const onSearchClick = () => {
        history.push('/teachers/?key=' + keyword.trim())
    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            onSearchClick()
            event.preventDefault();
        }
    };

    return (
        <div className="position-relative">
            <input type="text" className="form-control" placeholder="Nhập tên giáo viên"
                onKeyDown={handleEnter}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)} />
            <button className="btn btn-outline-dark border-0 position-absolute pr-3 py-2 top-0 right-0" onClick={onSearchClick}>
                <FontAwesomeIcon icon={allIcon.faSearch} />
            </button>
        </div>
    )
}

export { Teachers }