import { createSlice } from '@reduxjs/toolkit'

const initialState = () => { return { loggedIn: false, userToken: null, userInfomation: {}, needConfirmEmail: false }; }

export const authentication = createSlice({
  name: 'authentication',
  initialState: initialState(),
  reducers: {
    loginSuccessAction: (state, action) => {
      state['loggedIn'] = true;
      state['userToken'] = action.payload.userToken;
    },
    infomationSuccessAction: (state, action) => {
      state['userInfomation'] = action.payload;
      state['needConfirmEmail'] = !action.payload.emailConfirmed;
    },
    logoutAction: (state, action) => {
      // state = { loggedIn: false, userToken: null, userInfomation: {}, needConfirmEmail: false }
      state.loggedIn = false
      state.userToken = null
      state.userInfomation = {}
      state.needConfirmEmail = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { loginSuccessAction, infomationSuccessAction, logoutAction } = authentication.actions

export default authentication.reducer
