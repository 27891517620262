import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { BackButton } from "./BackButton";
import { userService } from "../services";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { alertActions } from "../actions";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from '@fortawesome/free-solid-svg-icons';

const SettingAccount = props => {
    window.gtag('event', 'page_view', {
        page_location: window.location
    })
    let authentication = useSelector(r => r.authentication);
    let { userInfomation } = authentication
    let [gender, setGender] = useState(userInfomation?.gender);
    let [displayName, setDisplayName] = useState(userInfomation?.displayName);
    let [dob, setDOB] = useState(null);
    let [phone, setPhone] = useState(userInfomation?.phoneNumber ?? '');
    // let [website, setWebSite] = useState();

    useEffect(() => {
        setGender(userInfomation.gender);
        setDisplayName(userInfomation.displayName);
        setPhone(userInfomation.phoneNumber ?? '');

        var parsed = moment(userInfomation.dob, 'DD/MM/YYYY');
        if (parsed.isValid()) {
            setDOB(parsed.toDate());
        }

    }, [userInfomation]);

    const onSubmit = () => {
        let model = {
            displayName: displayName,
            gender: gender,
            dob: dob == null ? null : moment(dob).format("DD/MM/YYYY"),
            phoneNumber: phone,
            avatar: userInfomation.avatar
        };
        userService.updateInfomation(model)
            .then(res => {
                if (res.isSuccess) {
                    alertActions.success("Cập nhật thành công");
                }
            })
            .finally(() => {

            });
    };

    return (
        <section className="">
            <section className="">
                {/* <BackButton /> */}
                <section className="">
                    <h1 className="">Thông tin cá nhân</h1>
                </section>
                <section className="">
                    <section className="form-group">
                        <label className="form-text">Giới tính</label>
                        <div className="form-check form-check-inline">
                            <input type="radio"
                                name="gender"
                                className="form-check-input"
                                value="1"
                                onChange={() => setGender(1)}
                                checked={gender == 1} />
                            <span className="form-check-label">Nam</span>
                        </div>

                        <div className="form-check form-check-inline ml-2">
                            <input type="radio"
                                name="gender"
                                className="form-check-input"
                                value="2"
                                onChange={() => setGender(2)}
                                checked={gender == 2} />
                            <span className="form-check-label">Nữ</span>
                        </div>
                    </section>
                    <div className="form-group">
                        <label className="Label_label">Email</label>
                        <div className="Input_inputWrap">
                            <input placeholder=""
                                name="fullname"
                                maxLength="50"
                                className="form-control"
                                readOnly
                                value={userInfomation?.email} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="">Họ và tên</label>
                        <div className="Input_inputWrap">
                            <input placeholder="eg. Nguyễn Văn A"
                                name="fullname"
                                maxLength="50"
                                className="form-control"
                                onChange={(e) => setDisplayName(e.target.value)}
                                value={displayName} />
                        </div>
                    </div>
                    <div className="form-group" >
                        <label className="">Ngày sinh</label>
                        <div>
                            <DatePicker selected={dob}
                                onChange={date => setDOB(date)}
                                className="form-control"
                                wrapperClassName="w-100"
                                placeholderText="ngày/tháng/năm (vd. 02/12/1990)"
                                dateFormat="dd/MM/yyyy"
                                name="birth"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                maxDate={new Date()}
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <div className="TextInput_container">
                        <label className="Label_label">Số điện thoại</label>
                        <div className="Input_inputWrap">
                            <input placeholder="eg. 0912345678"
                                name="phone"
                                maxLength="11"
                                className="form-control"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone} />
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="btn btn-success border-0 float-right px-3"
                            onClick={onSubmit}>Cập nhật
                        </div>
                    </div>
                </section>
            </section>
        </section>
    )
}

export { SettingAccount };