import React, { Component, useEffect } from 'react';
import { useLocation, withRouter } from "react-router-dom";

// class ScrollToTop extends Component {
//     componentDidUpdate(prevProps) {
//       if (this.props.location !== prevProps.location) {
//         window.scrollTo(0, 0)
//       }
//     }

//     render() {
//       return this.props.children
//     }
//   }

const ScrollToTop = (props) => {
  const location = useLocation();
  const pathname = location.pathname
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>
};
export default ScrollToTop
// export default withRouter(ScrollToTop)