import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";

export default class Error404 extends Component {

    constructor(props) {
        super(props)
    }

    //function which is called the first time the component loads
    componentDidMount() {
        window.gtag('event', 'page_view', {
            page_location: window.location
        })
    }

    render() {

        return (
            <section className="w-100">
                <section className="container py-5">
                    <div className='row'>
                        <div className='col-6'>
                            <img className='w-100' src='/assets/images/404.png' />
                        </div>
                        <div className="col-6">
                            <div className='d-flex align-items-center mt-3'>
                                <img className='w-50px' src='/assets/images/warning-ic.png' />
                                <span className='h3 fw-600 ml-2'>Không tìm thấy trang</span>
                            </div>
                            <p className='mt-3 mb-0 h5 fw-600'>Xin lỗi, trang bạn tìm hiện không tồn tại</p>
                            <p className='mt-3 mb-0 h5 fw-600'>Vui lòng quay trở lại trang chủ</p>
                            <Link to="/" className='btn btn-default bg-dark-orange text-white h5 px-4 rounded-lg mt-4'>Đi tới trang chủ</Link>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}