import { combineReducers } from 'redux';

import authentication from './authentication.reducer';
import systemConfiguration from './systemConfiguration.reducer';
import { alert } from './alert.reducer';
import loading from './loading.reducer';
import { learningExcercises } from './excercise.reducer';
import { modal } from './modal.reducer';
import { myCourses } from './myCourses.reducer';
import { themeConst } from '../constants';
import popupQueue from './popup.reducer';


const initialState = {
  sidebarShow: 'responsive',
}

const adminState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const defaultTheme = themeConst.light;

const themeState = (state = defaultTheme, { type, ...rest }) => {
  switch (type) {
    case 'SetTheme':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  adminState,
  systemConfiguration,
  authentication,
  alert,
  learningExcercises,
  loading,
  modal,
  myCourses,
  themeState,
  popupQueue
});

export default rootReducer;