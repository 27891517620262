import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ReactComponent as Show_password_icon } from "../../assets/images/show_password.svg";
import { ReactComponent as Hide_password_icon } from "../../assets/images/hide_password.svg";

const validator = new SimpleReactValidator();
const PasswordInput = (props) => {
    let { field, rawProps, name, label, onBlur, onChange, required } = props;
    let [isShow, setIsShow] = useState(false);
    let [render, setRender] = useState(0);
    let [value, setValue] = useState(field.value);

    const checkValidPassword = () => {
        field.checkValid();
        return field.valid;
    }

    field.onChecked = () => {
        reRender();
    }

    const onValueChange = (e) => {
        field.value = e.target.value;
        setValue(e.target.value);
        onChange?.call();
    }

    const reRender = () => {
        setRender(render + 1);
    }

    return (
        <>
            <label className="">{label} {required && <span>*</span>}</label>
            <div className="input-group" render={render}>
                <input placeholder=""
                    name={name}
                    type={isShow ? 'text' : 'password'}
                    maxLength="50"
                    autoComplete='new-password'
                    className={`form-control ${!field.valid && 'is-invalid'}`}
                    {...rawProps}
                    onChange={onValueChange}
                    onBlur={() => { checkValidPassword(); onBlur?.call() }}
                    value={value} />
                <span className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                        <span className="text-success"
                            onClick={() => setIsShow(!isShow)}>
                            {isShow ?
                                <Show_password_icon className='PasswordField_iconButton' /> :
                                <Hide_password_icon className='PasswordField_iconButton' />
                            }
                        </span>
                    </button>
                </span>
                {!field.valid &&
                    <div className="invalid-feedback">{field.errors[0]}</div>
                }
            </div>
        </>
    )
}

const TextInput = (props) => {
    let { field, rawProps, name, label, onBlur, onChange, required } = props;
    let [render, setRender] = useState(0);
    let [value, setValue] = useState(field.value);

    const checkValidPassword = () => {
        field.checkValid();
        return field.valid;
    }

    field.onChecked = () => {
        reRender();
    }

    const onValueChange = (e) => {
        field.value = e.target.value;
        setValue(e.target.value);
        onChange?.call();
    }

    const reRender = () => {
        setRender(render + 1);
    }

    return (
        <>
            <label className="">{label} {required && <span>*</span>}</label>
            <div className="input-group" render={render}>
                <input placeholder=""
                    name={name}
                    type='text'
                    className={`form-control ${!field.valid && 'is-invalid'}`}
                    {...rawProps}
                    onChange={onValueChange}
                    onBlur={() => { checkValidPassword(); onBlur?.call() }}
                    value={value} />
                {!field.valid &&
                    <div className="invalid-feedback" >{field.errors[0]}</div>
                }
            </div>
        </>
    )
}

export { PasswordInput, TextInput }