import { useState, useEffect, useRef } from "react";
import { Avatar } from "../shared";
import { connect } from "react-redux";
import ContentEditable from "react-contenteditable";
import { useSelector } from "react-redux";
import MDEditor, { commands } from '@uiw/react-md-editor';

const CommentInput = (props) => {
    let { userInfomation } = useSelector(s => s.authentication)
    let { onCommentOk, onCommentCancel, placeholder, autoFocus, innerRef } = props;
    let [content, setContent] = useState('');
    const commentOkClick = () => {
        onCommentOk(content);
        setContent('');
    };
    var input = useRef();

    useEffect(() => {
        // if (autoFocus)
        //     innerRef.current.focus();
    }, []);

    return (
        <div className="row flex-column">
            <div className="input-group">
                <div className="input-group-prepend">
                    <Avatar url={userInfomation?.avatar} className="w-40px h-40px rounded-circle" />
                </div>
                {/* <textarea
                    className="form-control border rounded-lg ml-2"
                    ref={innerRef}
                    placeholder={placeholder}
                    value={content}
                    style={{ resize: 'none' }}
                    onChange={(e) => { setContent(e.target.value) }}
                /> */}

                <MDEditor className="form-control border rounded-lg ml-2"
                    height={80}
                    value={content}
                    onChange={setContent}
                    hideToolbar
                    visibleDragbar={false}
                    enableScroll
                    preview="edit"
                    autoFocus={autoFocus}
                />
            </div>
            <div className="align-self-end mt-2">
                <div>
                    <button className="btn btn-dark" onClick={() => { setContent(''); onCommentCancel?.call(); }}>Hủy</button>
                    <button disabled={content == ''} className={`btn btn-success ml-3`} onClick={commentOkClick}>Bình luận</button>
                </div>
            </div>
        </div>
    );
}


export { CommentInput };