import { useState, useEffect, useRef } from "react";
import { Avatar } from "../shared";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import { commentService } from "../services";
import Moment from 'react-moment';
import { store } from "../helpers";
import { CommentInput } from "./index";
import MDEditor from '@uiw/react-md-editor';

const checkLiked = (comment) => {
    var userId = store.getState().authentication.userInfomation?.id;
    if (userId == null || comment.likes.length == 0) return false;
    return comment.likes.includes(userId);
}

const likeComment = (comment, success) => {
    let isLiked = checkLiked(comment);
    var task;
    if (isLiked) {
        task = commentService.unlikeComment(comment.id);
    } else {
        task = commentService.likeComment(comment.id);
    }
    task.then(res => {
        if (res.isSuccess) {
            let newComment = res.data;
            comment.likedUsers = newComment.likedUsers;
            comment.likes = newComment.likes;
            comment.likeCount = newComment.likes.length;
        }
        success?.call(res.isSuccess);
    })
};

const newReply = (comment, content, success) => {
    commentService.comment(comment.type, comment.objectId, comment.id, content)
        .then(res => {
            var newComment = res.data;
            success(newComment);
        });
}

const CommentItem = (props) => {
    const { comment, type, objectId } = props;
    let [showReply, setShowReply] = useState(false);
    let [replies, setReplies] = useState([]);
    let [newReplies, setNewReplies] = useState([]);
    let [isEnd, setIsEnd] = useState(false);
    let [temp, setTemp] = useState({});
    let [showReplyInput, setShowReplyInput] = useState(false);
    let input = useRef();

    const toggleReplies = () => {
        if (replies.length == 0) {
            loadReplies();
        }

        setShowReply(!showReply);
    }

    const loadReplies = () => {
        if (!isEnd && comment.replyCount > 0) {
            let from = replies.last()?.id ?? 0;
            commentService.getComments(comment.type, objectId, comment.id, from)
                .then(res => {
                    let result = replies.concat(res.data);
                    if (result.length == comment.replyCount) setIsEnd(true);
                    setReplies(result);
                });
        }
    }

    const likeClicked = () => {
        likeComment(comment, (success) => {
            setTemp({});
        });
    };

    const replyClicked = () => {
        if (showReplyInput) {
            input.current.focus();
        }
        setShowReplyInput(true);
    }

    const onCommentOk = (content) => {
        newReply(comment, content, (newComment) => {
            var news = newReplies.concat([newComment]);
            setNewReplies(news);
        });
        setShowReplyInput(false);
    }

    return (
        <div className="row flex-column">
            <div className="d-flex">
                <div className=""><Avatar url={comment.user.avatar} className='w-40px h-40px rounded-circle' /></div>
                <div className="ml-2 d-flex flex-column">
                    <div>
                        <div className="bg-light text-black px-3 py-2 position-relative" style={{ borderRadius: '10px' }}>
                            <div className="h6 fw-700">{comment.user.displayName}</div>
                            <div className="h6 my-0"><MDEditor.Markdown source={comment.content} style={{ whiteSpace: 'pre-wrap', backgroundColor: 'transparent' }} /></div>
                            {/* <div className="h6 my-0"><span>{comment.content}</span></div> */}
                            {comment.likeCount > 0 &&
                                <div className="position-absolute right-0 bottom-0 h6 pr-1 pb-1 text-orange my-0">
                                    <FontAwesomeIcon icon={allIcon.faThumbsUp} className='fa-w-16' />
                                    <span className="ml-1">{comment.likeCount}</span>
                                </div>
                            }
                        </div>
                    </div>

                    <CommentAction comment={comment} onLikeClicked={likeClicked} onReplyClicked={replyClicked} />
                    {comment.replyCount > 0 &&
                        <div className="Comment_viewRepliesMore" onClick={toggleReplies}>
                            <span className="btn btn-link h6 text-dark">
                                <span className="">{showReply ? 'Ẩn câu trả lời' : `Xem ${comment.replyCount} câu trả lời`}</span>
                                <FontAwesomeIcon icon={showReply ? allIcon.faAngleUp : allIcon.faAngleDown} className="fa-w-10 ml-1" />
                            </span>
                        </div>
                    }
                </div>
            </div>
            <div className="Comment_replyWrap" style={{ paddingLeft: '50px' }}>
                {showReply && replies.map(commentReplie => {
                    return (
                        <ReplyItem comment={commentReplie} key={commentReplie.id} onReplyClicked={replyClicked} />
                    );
                })}

                {newReplies.map(commentReplie => {
                    return (
                        <ReplyItem comment={commentReplie} key={commentReplie.id} onReplyClicked={replyClicked} />
                    );
                })}
                {showReplyInput &&
                    <div className="Comment_detailComment">
                        <div className="Comment_avatarWrap">
                        </div>
                        <div className="Comment_commentBody">
                            <CommentInput onCommentOk={onCommentOk} innerRef={input}
                                onCommentCancel={() => setShowReplyInput(false)}
                                placeholder="Bình luận công khai..."
                                autoFocus />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const ReplyItem = (props) => {
    const { comment, onReplyClicked } = props;
    let [temp, setTemp] = useState({});
    const likeClicked = () => {
        likeComment(comment, (success) => {
            setTemp({});
        });
    };

    const replyClicked = () => {
        // setShowReply(true);
    }
    return (
        <div className="row flex-column">
            <div className="d-flex">
                <div className=""><Avatar url={comment.user.avatar} className='w-40px h-40px rounded-circle' /></div>
                <div className="ml-2 d-flex flex-column">
                    <div>
                        <div className="bg-light text-black px-3 py-2 position-relative" style={{ borderRadius: '10px' }}>
                            <div className="h6 fw-700">{comment.user.displayName}</div>
                            <div className="h6 my-0"><MDEditor.Markdown source={comment.content} style={{ whiteSpace: 'pre-wrap', backgroundColor: 'transparent' }} /></div>
                            {comment.likeCount > 0 &&
                                <div className="position-absolute right-0 bottom-0 h6 pr-1 pb-1 text-orange my-0">
                                    <FontAwesomeIcon icon={allIcon.faThumbsUp} className='fa-w-16' />
                                    <span className="ml-1">{comment.likeCount}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <CommentAction comment={comment} onLikeClicked={likeClicked} onReplyClicked={onReplyClicked} />
                </div>
            </div>
        </div >
    );
}

const CommentAction = (props) => {
    const { comment, onLikeClicked, onReplyClicked } = props;
    let isLiked = checkLiked(comment);
    return (
        <div className="mt-1">
            <p className="h6">
                <span className="btn btn-link text-orange px-0 py-0">
                    <span className={`Comment_likeComment ${isLiked && 'Comment_liked'}`} onClick={onLikeClicked}>{'Thích'}</span>
                </span>
                <span className="px-1">·</span>
                <span className="btn btn-link text-orange px-0 py-0" onClick={onReplyClicked}>Trả lời</span>
                <span className="px-1">·</span>
                <button disabled className="text-dark btn btn-link px-0 py-0"><Moment fromNow locale="vi">{comment.createdDateTime}</Moment></button>
            </p>
        </div>
    );
}

export { CommentItem }