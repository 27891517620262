import ReactImageFallback from "react-image-fallback";


const KOCImage = (props) => {
    return (
        <ReactImageFallback
            fallbackImage="/assets/images/no-image-available.png"
            // initialImage="loader.gif"
            {...props}
        />
    )
}

export { KOCImage }