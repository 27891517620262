import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import throttle from "lodash.throttle"
import { Avatar } from '../shared';
import { Carousel } from "react-responsive-carousel";
import { userService } from '../services';
import { useQuery } from 'react-query';

const reviewData = [
    { author: 'Mẹ Ngân Nguyễn', content: 'Trước đây mình khá stress vì nghĩ mình chưa có kiến thức dạy con, sợ rằng dạy con theo bản năng sẽ không ổn. Rất may vì học được những điều hay từ Thầy Quân, nên cũng dần dần hiểu và áp dụng, thấy tự tin rằng con đường mình đang đi là đúng, không còn lo lắng như trước nữa.' },
    { author: 'Mẹ Nga Tĩnh Lặng', content: 'Em chân thành cám ơn thầy Trần Việt Quân, Thầy Dương Quang Minh, và nhóm cộng sự BKE. Đã làm thay đổi một con người, đã đánh thức ý nghĩa cuộc đời em, em đã lan tỏa được ý nghĩa và giá trị khóa học dạy con từ nền tảng cốt lõi đến nhiều người.' },
    { author: 'Mẹ Bùi Hồng Phượng', content: 'Thật may là mình đã "vớ" được Thầy Quân thật đúng lúc. Nhờ thầy mà mình thấy mình mới chỉ dạy con thôi, còn rèn con thì vẫn chưa đủ. Mình cũng hiểu thật trọn vẹn câu "Mẹ phải thật hạnh phúc thì mới khiến con hạnh phúc được". Cảm ơn thầy rất nhiều' }
]

const HomeReview = () => {

    const { data: apiResult, isFetching } = useQuery(['testimotions'], () => { return userService.getTestimotions() }, { staleTime: Infinity })
    let testimotions = apiResult?.data ?? [];

    return (
        <section className='bg-light-pink py-5'>
            <section className="container">
                <div className="row text-center">
                    <div className="col-12 mt-4 ">
                        <div className="h2 fw-700 text-uppercase">Cảm nhận của học viên</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className='col-5 d-none d-lg-block'>
                        <img src='/assets/images/testimotion-img.png' className='w-100 mt-5' />
                    </div>
                    <div className='col-1'>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='text-orange'>
                            <FontAwesomeIcon icon={allIcon.faQuoteLeft} size='5x' className='d-none d-lg-block' />
                        </div>
                        <Carousel showArrows={true} showThumbs={false} showIndicators={true} showStatus={false} autoPlay={false} infiniteLoop className='mt-4 pt-4'>
                            {testimotions.map((reviewItem, index) =>
                            (
                                <Item reviewItem={reviewItem} key={index} />
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section>
        </section>
    )
}

const Item = ({ reviewItem }) => {
    return (
        <div className='w-100 w-100 text-left'>
            <div className='h3' dangerouslySetInnerHTML={{ __html: reviewItem.description }}></div>
            <div className="course-author d-flex align-items-center mt-5">
                <Avatar url={reviewItem.avatar} className='w-60px h-60px rounded-circle objectfit-cover' />
                <div className="ml-2">
                    <span className="text-black">{reviewItem.name}</span><br />
                    <span className="text-orange">{reviewItem.introduce}</span>
                </div>
            </div>
        </div>
    )
}

export { HomeReview }