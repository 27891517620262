import { useState, useEffect } from "react";
import { CourseGridItem } from "../courses";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
// import feature1 from "../assets/images/feature-1.png";
// import feature2 from "../assets/images/feature-2.png";
// import feature3 from "../assets/images/feature-3.png";

const HomeShortFeature = (props) => {

    let features = [
        { name: 'học viên', value: '3M+' },
        { name: 'khóa học', value: '600+' },
        { name: 'Giảng viên', value: '130+' },
        { name: 'hài lòng', value: '90%' },
    ]

    return (
        <section className="bg-orange mt-3">
            <section className="w-100 position-relative">
                <div className="container">
                    <div className="w-100 position-relative">
                        <img src="/assets/images/map.png" className="w-100 objectfit-contain" />
                        <section className="d-none d-lg-flex justify-content-between align-items-center w-100 h-100 position-absolute top-0 text-uppercase">
                            {features.map((item, index) =>
                                <section className="text-center" key={index}>
                                    <div className="display-3 fw-900">{item.value}</div>
                                    <div className="h2 fw-700">{item.name}</div>
                                </section>
                            )}
                        </section>


                        <section className="d-none d-sm-flex d-lg-none justify-content-between align-items-center w-100 h-100 position-absolute top-0 px-5 text-uppercase">
                            {features.map((item, index) =>
                                <section className="text-center" key={index}>
                                    <div className="h1 fw-900">{item.value}</div>
                                    <div className="h5 fw-700">{item.name}</div>
                                </section>
                            )}
                        </section>

                        <section className="d-flex d-sm-none justify-content-between align-items-center w-100 h-100 position-absolute top-0 px-3 text-uppercase">
                            {features.map((item, index) =>
                                <section className="text-center" key={index}>
                                    <div className="h3 fw-900">{item.value}</div>
                                    <div className="h6 fw-700"><small>{item.name}</small></div>
                                </section>
                            )}
                        </section>
                    </div>
                </div>


            </section>
        </section>
    )
}
function mapState(state) {
    return { loggedIn: state.authentication.loggedIn };
}

const actionCreators = {
};

export { HomeShortFeature };