import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, withRouter } from "react-router-dom";
import queryString from 'query-string';
import SuccessIcon from "../assets/images/success.svg";
import ErrorIcon from "../assets/images/error.svg";
import { userService } from "../services";
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export class EmailConfirmation extends Component {
    constructor(props) {
        super(props)
        let params = queryString.parse(this.props.location.search);
        this.state = {
            token: params.u,
            isLoading: true,
            isSuccess: false
        };
        this.confirmEmail = this.confirmEmail.bind(this);
    }

    componentDidMount() {
        this.confirmEmail();
    }

    confirmEmail() {
        if (this.state.token == null || this.state.token == '') {
            this.setState({
                isLoading: false
            });
            return;
        }

        userService.confirmEmail(this.state.token)
            .then(res => {
                this.setState({
                    isSuccess: res.isSuccess
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });
    }

    render() {

        return (
            <section className="">
                <section className="container">
                    <section className="row justify-content-center mt-5">
                        <section className="text-center" style={{ width: '400px' }}>
                            <section className="border shadow px-3 py-3">
                                <div className="h3 fw-700">Xác minh tài khoản</div>
                                {this.state.isLoading ?
                                    <div>
                                        <FontAwesomeIcon icon={allIcon.faSpinner} />
                                    </div> :
                                    <section className={`${this.state.isSuccess ? 'text-success' : 'text-danger'}`}>
                                        <span className="mr-2">
                                            <img src={this.state.isSuccess ? SuccessIcon : ErrorIcon} className='w-20px' />
                                        </span>
                                        {this.state.isSuccess ? 'Xác minh thành công. Bạn chính thức là thành viên của KOC Academy ❤️' :
                                            'Liên kết đã hết hạn. Nếu tài khoản của bạn chưa được xác thực, vui lòng đăng nhập và gửi lại email xác minh.'}
                                    </section>
                                }
                                <Link to='/' className='pt-5'>
                                    Trang chủ
                                </Link>
                            </section>
                            <section className="h6 mt-3">
                                <p>Bạn cần sự hỗ trợ? <a href="mailto:info@tambooks.edu.vn">Gửi email cho chúng tôi</a></p>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        )
    }
}