import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import { UrlHelpers } from "../helpers";
import { courseService, userService } from "../services";
import { PurchaseBadge } from "./PurchaseBadge";
import { CourseComment } from "./CourseComment";
import { CourseWillLearn } from "./CourseWillLearn";
import { CourseRequire } from "./CourseRequire";
import Moment from 'react-moment';
import moment from 'moment';
import { history } from "../helpers";
import { NoData } from "../shared";
import { alertActions, modalActions } from "../actions";
import { RelatedCourses } from './RelatedCourses';
import {
    useParams
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { useForceUpdate } from '../helpers/useForceUpdate';
import { KOCImage } from '../shared/KocImage';
import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import { useForm } from 'react-hook-form';

const CourseDetail = props => {

    let authentication = useSelector(r => r.authentication)
    let { id } = useParams()
    let [showFormRegis, setShowFormRegis] = useState(false)

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_location: window.location
        })

        courseService.getCourseDetail(id)
            .then(res => {
                setCourseDetail(res.data)

                window.gtag('event', 'view_item', {
                    items: [{
                        item_id: id,
                        item_name: res.data.course.name,
                        item_category: res.data.category?.name,
                        price: res.data.course.price,
                        discount: res.data.course.originPrice - res.data.course.price,
                        currency: "VND",
                    }]
                })
            });

        courseService.getCourseLessons(id)
            .then(res => {
                let { chapters, lessons, userLessons } = res.data;
                if (userLessons)
                    for (let i = 0; i < userLessons.length; i++) {
                        const userLesson = userLessons[i];
                        let lesson = lessons.find(r => r.id == userLesson.lessonId);
                        if (lesson)
                            lesson.completed = true;
                    }

                chapters.forEach(item => {
                    item.expanded = true;
                });

                setCourseLessons(res.data)
            });
    }, [id])

    const [courseDetail, setCourseDetail] = useState({})
    let [courseLessons, setCourseLessons] = useState({ chapters: [], lessons: [], userLessons: [] })

    const { course, category, userCourse } = courseDetail
    const { chapters, lessons, userLessons } = courseLessons
    const isRegistered = userCourse != null;


    const formHook = useForm({})
    const { register, handleSubmit, formState: { errors }, control, getValues, reset } = formHook

    const onsubmit = (e) => {
        var model = {
            fullName: e.fullName,
            email: e.email,
            phone: e.phone,
            note: e.note,
            courseId: course.id,
            courseName: course.name
        }
        setShowFormRegis(false)
        userService.courseV2Request(model)
            .then(res => {
                if (res.isSuccess) {
                    alertActions.success('Cảm ơn bạn đã để lại thông tin. Chúng tôi sẽ liên hệ với bạn trong thời gian gần nhất.')
                    reset({
                        fullName: '',
                        email: '',
                        phone: '',
                        note: '',
                    })
                }
            });
    }

    const registerClicked = () => {

        if (!authentication.loggedIn) {
            modalActions.show({
                title: 'Vui lòng đăng nhập để tham gia khóa học.',
                ok: 'Đăng nhập',
                onOk: () => {
                    history.login();
                }
            });
            return;
        }

        if (authentication.needConfirmEmail) {
            alertActions.error('Tài khoản của bạn chưa được xác thực. Vui lòng kiểm tra hòm thư và xác thực tài khoản.');
            return;
        }

        if (isRegistered) {
            const learningPath = UrlHelpers.createLearningUrl(course.slug, course.id);
            history.push(learningPath);
            return;
        }

        if (course?.type === 'V2' || course?.type === 'ZOOM' || !course?.price) {
            registerCourseV2()
            return
        }

        registerCourseV1();
    }

    const registerCourseV2 = () => {
        setShowFormRegis(true)
    }

    const registerCourseV1 = () => {
        if (course.price > 0) {
            history.push(`/checkout/${course.slug}/${course.id}`);
            return;
        }

        courseService.registerCourse(course.id)
            .then(res => {
                if (res.isSuccess) {
                    const learningPath = UrlHelpers.createLearningUrl(course.slug, course.id);
                    history.push(learningPath);
                }
            })
            .finally(() => { });
    }

    if (course == null)
        return (
            <section className="w-100">
                <section className="container">
                    <section className="row">
                        <section className="col-md-12 col-lg-8">
                            Loading...
                        </section>
                    </section>
                </section>
            </section>
        );


    return (
        <section className="w-100">
            <div className='d-flex flex-column bg-light-pink pb-3'>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Sản phẩm</h1>
                            {/* <div className="h3 text-uppercase fw-400" >Trang chủ / Chi tiết khóa học</div> */}
                        </div>
                    </div>
                </div>
                {/* <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div> */}
            </div>
            <div className="container py-5">
                <div className="row ">
                    <div className="col-md-12 col-lg-7">
                        <div className='position-relative' style={{ paddingTop: '60%' }}>
                            <div className='position-absolute top-0 w-100 h-100'>
                                <KOCImage src={course.thumbnailImage} className='w-100 h-100 objectfit-cover rounded-15px' />
                            </div>
                        </div>
                        <div className="h2 fw-600 mt-2">{course.name}</div>
                        <div className="mt-2" dangerouslySetInnerHTML={{ __html: course.description }}></div>
                        {/* <CourseWillLearn course={course} /> */}
                        {course?.type === "V1" && <CourseContent chapters={chapters} lessons={lessons} userLessons={userLessons} course={course} isRegistered={isRegistered} />}
                        {/* <CourseRequire course={course} /> */}

                    </div>
                    <div className="col-md-12 col-lg-5">
                        <PurchaseBadge course={course} lessons={lessons} isRegistered={isRegistered} registerClicked={registerClicked} />
                        <CourseWillLearn course={course} />
                        <CourseRequire course={course} />
                    </div>
                    <div className='col-md-12 col-lg-7 mt-4'>
                        {course?.type === "V2" ? null : <RelatedCourses courseId={id} />}
                        <CourseComment course={course} />
                    </div>

                </div>
            </div>
            <CModal onClose={() => { setShowFormRegis(false) }} show={showFormRegis}>
                <CModalHeader closeButton className='h3'>Đăng ký</CModalHeader>
                <CModalBody>
                    <div className='row mt-3 px-0'>
                        <div className='w-100 text-center'>Bạn có thể xem thông tin chi tiết tại <a target='_blank' href='https://www.facebook.com/tambooksyoga' rel="noreferrer">đây</a>. Hoặc để lại thông tin</div>
                    </div>
                    <div className="w-100 row mt-3 px-0">
                        <div className="col-md-12 col-lg-12">
                            <div className="input-group mb-3">
                                <input placeholder="Họ tên của bạn"
                                    type="text"
                                    name="fullName"
                                    maxLength="200"
                                    className={`form-control border-orange`}
                                    {...register('fullName')}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <input placeholder="Địa chỉ email của bạn"
                                    type="text"
                                    name="email"
                                    maxLength="100"
                                    className={`form-control border-orange`}
                                    {...register('email')}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <input placeholder="Số điện thoại của bạn"
                                    type="text"
                                    name="phone"
                                    maxLength="50"
                                    className={`form-control border-orange`}
                                    {...register('phone')}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <textarea placeholder="Ghi chú"
                                    type="text"
                                    name="note"
                                    maxLength={255}
                                    style={{ height: "100px" }}
                                    className={`form-control border-orange`}
                                    {...register('note')}
                                />
                            </div>
                            <button className="btn btn-default bg-dark-orange text-white h5 px-3 w-100px float-right"
                                onClick={handleSubmit(onsubmit)}
                            >Gửi</button>
                        </div>
                    </div>
                </CModalBody>
            </CModal>
        </section>
    );
}

const CourseContent = ({ chapters, lessons, userLessons, isRegistered, course, registerCourse }) => {

    let forceUpdate = useForceUpdate()

    const isAllChapterExpanded = () => {
        return !chapters?.some(item => item.expanded !== true);
    }

    const toggleChapter = (index) => {
        chapters[index].expanded = chapters[index].expanded != true
        forceUpdate()
    }

    const toggleAll = () => {
        let isAllExpended = isAllChapterExpanded();
        chapters.forEach(item => item.expanded = !isAllExpended);
        forceUpdate()
    }
    const getDurationNumber = () => {
        const totalTime = lessons.reduce((total, item) => total + item.timeLength, 0);
        return totalTime;
    }
    let start = moment().add(0 - getDurationNumber(), 's');

    return (
        <div className="w-100">
            <div className="content-list-header w-100">

                <h5 className="fw-700">Nội dung khóa học</h5>
                <div className="d-flex flex-row w-100 justify-content-between">
                    <div className='fs-13'>
                        <span>{chapters.length} phần</span>
                        <span className='mx-2'>-</span>
                        <span>{lessons.length} bài học</span>
                        <span className='mx-2'>-</span>
                        <span>Thời lượng <Moment date={start} format="hh [giờ] mm [phút]" trim durationFromNow /></span>
                    </div>
                    <div onClick={toggleAll}>{isAllChapterExpanded() ? 'Thu nhỏ' : 'Mở rộng'}</div>
                </div>

            </div>
            <div className="content-list pt-2">
                {lessons.length == 0 ?
                    <div className="chapter-item-wrap">
                        <NoData message="Chưa có bài học. Bạn vui lòng quay lại sau." />
                    </div> :
                    <div className="chapter-item-wrap">
                        {chapters.map((chapter, chapterIndex) =>
                            <ChapterItem key={chapterIndex}
                                chapter={chapter}
                                lessons={lessons.filter(r => r.chapterId == chapter.id)}
                                chapterIndex={chapterIndex}
                                toggleChapter={toggleChapter}
                                userLessons={userLessons}
                                isRegistered={isRegistered}
                                registerCourse={registerCourse}
                                course={course} />
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

function ChapterItem({ chapter, chapterIndex, toggleChapter, lessons, isRegistered, registerCourse, course }) {
    let authentication = useSelector(r => r.authentication)
    const { expanded } = chapter;
    const timeLength = lessons.reduce((total, item) => total + item.timeLength, 0);

    const lessonClicked = (lesson, chapter) => {
        // if (!authentication.loggedIn) {
        //     modalActions.show({
        //         title: 'Vui lòng đăng nhập để tham gia khóa học.',
        //         ok: 'Đăng nhập',
        //         onOk: () => {
        //             history.login();
        //         }
        //     });
        //     return;
        // }

        // if (!isRegistered) {
        //     modalActions.show({
        //         title: 'Để xem nội dung bạn cần đăng ký tham gia khóa học.',
        //         onOk: () => {
        //             registerCourse();
        //         }
        //     })
        //     return;
        // }
        // const learningPath = UrlHelpers.createLearningUrl(course.slug, course.id, lesson.slug, lesson.id);
        // history.push(learningPath);
    }

    return (
        <div className="chapter-item" key={chapter.id}>
            <div className={"pl-3 pr-2 py-2 border bg-light-pink"} onClick={() => toggleChapter(chapterIndex)}>
                <div className="d-flex flex-row">
                    <span className='pr-2'><FontAwesomeIcon icon={expanded ? allIcon.faMinus : allIcon.faPlus} size='sm' /></span>
                    <span className="mr-auto">
                        <strong>{chapter.name}</strong>
                    </span>
                    <span className="w-100px text-center">{lessons.length} bài học</span>
                    <span className="w-100px text-right">{moment.duration(timeLength, 'seconds').format("hh:mm:ss")}</span>
                </div>
            </div>
            <div className={"" + (expanded ? '' : 'd-none')}>
                <div className="lesson-list">
                    <div className='lesson-list-wrap '>
                        {lessons.map((lesson, lessionIndex) =>
                            <div className="border-bottom lesson-item-wrap py-2 pl-4 pr-2" key={lessionIndex} onClick={() => lessonClicked(lesson, chapter)}>
                                <div className='d-flex flex-row'>
                                    <span className="pr-2">
                                        {lesson.completed ?
                                            <FontAwesomeIcon icon={allIcon.faCheck} size='sm' /> :
                                            <FontAwesomeIcon icon={isRegistered ? allIcon.faPlayCircle : allIcon.faLock} size='sm' />
                                        }
                                    </span>
                                    <span className='mr-auto'>{lesson.name}</span>
                                    <span className="w-100px text-center">   {lesson.type}   </span>
                                    <span className="w-100px text-right">{moment.duration(lesson.timeLength, 'seconds').format("hh:mm:ss")}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { CourseDetail };