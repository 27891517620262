import { hot } from 'react-hot-loader';
import React, { useEffect, useState } from 'react';
import { Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import { history, string_isNullOrEmpty, UrlHelpers } from './helpers';
import { alertActions, userActions, learningExcercisesActions } from './actions';
import Footer from './footer/Footer';
import { NavigationBar } from './navigation-bar';
import { Home, AboutUs, Contact, FAQ } from './home';
import { SettingMain } from './settings';
import { Courses } from './courses';
import { CourseDetail } from './course-detail';
import { LearningLesson } from './learning';
import { Login, Register, ForgotPassword, ResetPassword, EmailConfirmation, EmailConfirmationHeader } from './auth';
import ScrollToTop from "./ScrollToTop";
import Error404 from './error/Error404';
import { LessonExcercises } from './excercise/LessonExcercises';
import { Loading, AlertComponent, Modal } from "./shared";

import { CourseCheckout } from './course-detail';
import { bannerService, contentPageService, userService } from './services';
import { ContentPage } from './home/ContentPage';
import { LiveSchedules } from './live-schedules/';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SimpleReactValidator from 'simple-react-validator';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Assessment } from './assessments/Assessment';
import { Blog } from './blog/Blog';
import { TeacherDetail } from './courses/TeacherDetail';
import { Teachers } from './courses/Teachers';
import { addPopupAction, removePopupAction } from './reducers/popup.reducer';
import BaseLink from './shared/BaseLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import { systemService } from './services/system.service';
import { systemConfigurationSuccessAction } from './reducers/systemConfiguration.reducer';
import { MyCourses } from './courses/MyCourses';
import { MyCerts } from './courses/MyCerts';
import { CoursesV2 } from './courses/CoursesV2';
import { CoursesZoom } from './courses/CoursesZoom';

const App = () => {

  window.dataLayer?.push({
    event: 'pageview'
  });
  let dispatch = useDispatch()
  let { loggedIn } = useSelector(s => s.authentication)
  let [contentPages, setContentPages] = useState([])
  let [addAccessPopup, setAddAccessPopup] = useState(false)

  const { data: bannersResult, isFetching, isFetched } = useQuery(['banners'], () => { return bannerService.getAll() }, { staleTime: Infinity })
  let banners = bannersResult?.data ?? [];
  banners = banners.filter(r => r.active && r.type == 'popup')
  let hasBanner = banners.length > 0

  useEffect(() => {
    contentPageService.getContentPagesBrief()
      .then(res => {
        if (res.isSuccess) {
          setContentPages(res.data)
        }
      });

    systemService.getConfigs()
      .then(res => {
        if (res.isSuccess) {
          dispatch(systemConfigurationSuccessAction(res.data))
        }
      });
  }, [])

  useEffect(() => {
    if (!addAccessPopup && isFetched) {
      var accessPopups = banners.filter(r => r.event == 'ACCESS')
      if (accessPopups.length > 0) {
        dispatch(addPopupAction(accessPopups))
      }
      setAddAccessPopup(true)
    }
  }, [isFetched])

  return (
    <>
      {/* <ThemeComponent /> */}
      <ScrollToTop>
        <div className='application'>
          <Switch>
            <Route path={UrlHelpers.learningPath} component={LearningLesson}></Route>
            <>
              <NavigationBar />
              <Switch>

                <Route path='/register' exact component={Register} />
                <Route path='/login' exact component={Login} />
                <Route path='/forgot-password' exact component={ForgotPassword} />
                <Route path='/email-confirmation' component={EmailConfirmation} />
                <Route path='/reset-password' component={ResetPassword} />
                <Route path="/" exact component={Home} />
                <Route path="/about-us" exact component={AboutUs} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/faq" exact component={FAQ} />
                <Route path="/live-schedules" component={LiveSchedules} />
                <Route path="/books" component={Blog} />
                {loggedIn &&
                  <Route path="/user/*" exact component={SettingMain} />
                }
                <Route path="/courses/:filterType?/:name?/:id?" component={Courses} />
                <Route path="/courses-v2/:filterType?/:name?/:id?" component={CoursesV2} />
                <Route path="/courses-zoom/:filterType?/:name?/:id?" component={CoursesZoom} />
                <Route path="/my-courses/" component={MyCourses} />
                <Route path="/my-certs/" component={MyCerts} />
                <Route path="/teacher/:teacherId?/:name?" component={TeacherDetail} />
                <Route path="/teachers" component={Teachers} />
                <Route path="/assessment/:attemptGuid" component={Assessment} />
                <Route path={UrlHelpers.courseDetailPath} exact component={CourseDetail}></Route>
                <Route path={UrlHelpers.courseCheckout} exact component={CourseCheckout}></Route>
                {contentPages.map(item => (
                  <Route path={item.url} exact component={Register} key={item.id}>
                    <ContentPage id={item.id} />
                  </Route>
                ))}
                <Route component={Error404} />
              </Switch>
            </>
          </Switch>
          <Footer />
        </div>
      </ScrollToTop>
      <PopupManager />
      {process.env.NODE_ENV != "development" &&
        < MessengerCustomerChat
          pageId={process.env.REACT_APP_FacebookPageId}
          appId={process.env.REACT_APP_FacebookAppId}
          version='12.0'
        />
      }
    </>
  );
}

const PopupManager = () => {
  let dispatch = useDispatch()
  let popupQueue = useSelector(r => r.popupQueue)
  let { popups } = popupQueue
  let hasPopup = popups.length > 0
  let popup = popups[0]

  let onClose = () => {
    dispatch(removePopupAction({ popupIndex: 0 }))
  }

  const getMobiImage = () => {
    if (!string_isNullOrEmpty(popup.imageMobile))
      return popup.imageMobile

    return popup.image
  }

  return (
    <>
      {hasPopup &&
        <section className="position-fixed w-100 h-100 d-flex align-items-center overflow-auto top-0 left-0" style={{ zIndex: 10000, backgroundColor: '#00000055' }}>
          <section className="position-relative mx-auto" style={{ maxHeight: '100vh' }}>
            <BaseLink to={popup.link} target={popup.newTab ? '_blank' : ''}>
              <img src={popup.image} className='objectfit-cover d-none d-md-block' style={{ maxWidth: '80vw' }} />
              <img src={getMobiImage()} className='objectfit-cover d-block d-md-none' style={{ maxWidth: '80vw' }} />
            </BaseLink>
            <div className='btn btn-light position-absolute top-0 right-0 text-danger' onClick={onClose}><FontAwesomeIcon icon={allIcon.faTimes} size='lg' /></div>
          </section>
        </section>
      }
    </>
  )
}

const ThemeComponent = (props) => {
  const theme = useSelector(state => state.themeState)
  useEffect(() => {
    document.body.setAttribute('theme', theme.name);

    return () => {
      document.body.removeAttribute('theme');
    }
  }, [theme]);

  return (
    <>
    </>
  )
}

export default App;
// export default hot(module)(App);
