import APICONFIGS from '../admin-api-configs.json';
import { UrlHelpers, customFetch, authHeader, jsonHeader } from '../../helpers';

export const AuthorService = {
    getAuthors,
    deleteAuthor,
    AuthorCreate,
    AuthorUpdate
}

function getAuthors() {
    let requestOptions = {
        method: 'GET',
        cache: 'no-cache',
        headers: authHeader()
    };
    let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.GetAllAuthor);
    return customFetch(apiUrl, requestOptions)
        .then(res => res);
}

function deleteAuthor(authorId) {
    let requestOptions = {
        method: 'DELETE',
        cache: 'no-cache',
        headers: authHeader()
    };
    let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.DeleteAuthor);
    return customFetch(apiUrl.format(authorId), requestOptions)
        .then(res => res);
}

function AuthorCreate(model) {
    let requestOptions = {
        method: 'POST',
        cache: 'no-cache',
        headers: authHeader(jsonHeader()),
        body: JSON.stringify(model)
    };
    let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.AuthorCreate);
    return customFetch(apiUrl, requestOptions)
        .then(res => res);
}

function AuthorUpdate(model) {
    let requestOptions = {
        method: 'PUT',
        cache: 'no-cache',
        headers: authHeader(jsonHeader()),
        body: JSON.stringify(model)
    };

    let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.AuthorUpdate);
    return customFetch(apiUrl, requestOptions)
        .then(res => res);
}
