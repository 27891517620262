import { useQuery } from "react-query";
import { userService } from "../services";
import BootstrapTable from 'react-bootstrap-table-next';

const MyRanking = () => {

    const { data: myRankResult } = useQuery(["my-rank"], () => userService.getMyRank());
    const { data: topRankResult } = useQuery(["top-rank"], () => userService.getTopRank());

    const columns = [
        {
            dataField: 'rank',
            text: 'Xếp hạng',
            type: 'number',
            sort: false,
            headerStyle: {
                textAlign: 'center',
                backgroundColor: "#ffdfc9",
                color: '#ef7d30',
            },
            style: {
                width: '120px',
                textAlign: 'center',
                backgroundColor: '#fff0e6',
            },

        },
        {
            dataField: 'displayName',
            text: 'Tên người dùng',
            sort: false,
            canFilter: false,
            type: 'string',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: '#ffdfc9',
                color: '#ef7d30',
            },
            style: {
                textAlign: 'center',
                backgroundColor: '#fff0e6',
            },

        },
        {
            dataField: 'userName',
            text: 'Tài khoản',
            sort: false,
            canFilter: false,
            type: 'string',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: '#ffdfc9',
                color: '#ef7d30',
            },
            style: {
                textAlign: 'center',
                backgroundColor: '#fff0e6',
            },

        },
        {
            dataField: 'rankPoint',
            text: 'Điểm',
            sort: false,
            canFilter: false,
            type: 'string',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: "#ffdfc9",
                color: '#ef7d30',
            },
            style: {
                width: '200px',
                textAlign: 'center',
                backgroundColor: '#fff0e6',
            },
        },
    ]

    return (
        <div className="">
            <div className="d-flex justify-content-center mb-5">
                <div className="w-50 pt-4 pb-4 bg-light-orange border border-orange rounded-15px row">
                    <div className='col-12 text-center mb-3'>
                        <span className='h3 text-orange'>Xếp hạng của tôi</span>
                    </div>
                    <div className="col-6">
                        <div className="border border-orange rounded-15px text-center bg-white pt-2 pb-2">
                            <span className="h6 text-black">Point: </span><span className="h5 text-orange">{myRankResult?.data?.rankPoint}</span>
                        </div>

                    </div>
                    <div className="col-6 ">
                        <div className="border border-orange rounded-15px text-center bg-white pt-2 pb-2">
                            <span className="h6 text-black">Xếp hạng: </span><span className="h5 text-orange">{myRankResult?.data?.rank}</span>
                        </div>
                    </div>
                </div>
            </div>

            <BootstrapTable
                striped
                hover
                keyField="id"
                data={topRankResult?.data || []}
                columns={columns}
            />
        </div>
    )
}

export default MyRanking
