import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import { Avatar, NoData } from "../shared";
import { CourseGridItem, CourseGridItem2 } from './CourseGridItem';
import { courseService } from "../services/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import { history, string_isNullOrEmpty } from '../helpers';
import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import { useQuery } from 'react-query';
import ReactPlayer from 'react-player';

const _ = require("lodash");

const FILTER_TYPE = {
    NEWEST: 'all',
    FEATURED: 'featured',
    HOT: 'hot',
    CATEGORY: 'categories',
    TEACHER: 'teacher',
    SEARCH: 'search'
}

const SHORT_TYPE = {
    Default: "default",
    Name: "name",
    Newest: "newest",
    PriceASC: "price-asc",
    PriceDESC: "price-desc",
}

const SHORT_FUNC = {
    "default": (a, b) => { return 1; },
    "name": (a, b) => { return a.name.localeCompare(b.name); },
    "newest": (a, b) => { return -a.id + b.id; },
    "price-asc": (a, b) => { return a.price - b.price; },
    "price-desc": (a, b) => { return -a.price + b.price; },
}

const TeacherDetail = () => {
    let { teacherId, name } = useParams()
    let [courses, setCourses] = useState([])
    let [sortType, setSortType] = useState(SHORT_TYPE.default)

    const { data: authorResult, isSuccess } = useQuery(["courses-authors", teacherId], () => courseService.getAuthorDetail(teacherId), { staleTime: 600000 });
    const author = authorResult?.data

    const sortTypeChanged = (e) => {
        setSortType(e.target.value);
    }

    const getCourses = () => {
        courseService.getCoursesByTeacher(teacherId)
            .then(
                res => {
                    setCourses(res.data);
                },
                error => {
                }
            );
    }

    useEffect(() => {
        getCourses()
    }, [teacherId])

    let sortedCourses = [...courses].sort(SHORT_FUNC[sortType]);

    let totalRegistered = _.sum(courses.map(r => r.registered ?? 0))
    let totalRate = _.sum(courses.map(r => r.totalRate ?? 0))
    let totalStar = _.sum(courses.map(r => r.totalStar ?? 0))
    let avgStar = totalRate == 0 ? 0 : Math.round(totalStar / totalRate * 10) / 10

    let hasDescription = !string_isNullOrEmpty(author?.description)
    let hasIntroVideo = !string_isNullOrEmpty(author?.introVideo)

    return (
        <section className="w-100">
            <section className='d-flex flex-column bg-light-pink'>
                <section className="container flex-grow-1">
                    <section className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            {/* <h1 className="text-uppercase mb-1 h1 fw-700">Khóa học</h1>
                            <div className="h3 text-uppercase fw-400" >Trang chủ / Khóa học</div> */}

                        </div>
                    </section>
                </section>
                <div className='w-100 pb-3'>
                    {/* <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' /> */}
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 d-block d-md-flex py-1'>
                                <div className='d-flex justify-content-center'>
                                    <Avatar url={author?.avatar} className='rounded-circle w-150px h-150px' />
                                    {/* <Avatar url={author?.avatar} className='rounded-circle w-100px h-100px d-lg-none d-block' /> */}
                                </div>
                                <div className='text-dark-orange pt-1 ml-4 text-md-left text-center'>
                                    <div className='h1 fw-600 my-0' title={author?.name}>{author?.name}</div>
                                    <div className='h5'>{author?.introduce}</div>
                                    <div className='mt-1'>
                                        <a target='_blank' href={author?.facebook} className=''><img src='/assets/images/facebook-ic.png' className='w-40px' /></a>
                                        <a target='_blank' href={author?.instagram} className='ml-3'><img src='/assets/images/instagram-ic.png' className='w-40px' /></a>
                                        <a target='_blank' href={author?.twitter} className='ml-3'><img src='/assets/images/twitter-ic.png' className='w-40px' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 d-flex text-dark-orange justify-content-between text-center align-items-center py-1'>
                                <div>
                                    <p className='h1 fw-700'>{courses.length}</p>
                                    <p className='h5'>Khóa học</p>
                                </div>

                                <div>
                                    <p className='h1 fw-700'>{totalRegistered}</p>
                                    <p className='h5'>Học viên</p>
                                </div>

                                <div>
                                    <p className='h1 fw-700'>{totalRate}</p>
                                    <p className='h5'>Đánh giá</p>
                                </div>

                                <div>
                                    <p className='h1 fw-700'>{avgStar} <FontAwesomeIcon icon={allIcon.faStar} /></p>
                                    <p className='h5'>Trung bình</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container py-5">
                {(hasDescription || hasIntroVideo) &&
                    <div className='row justify-content-center border border-orange rounded-10px py-3 bg-light-pink'>

                        <div className={`col-12 ${hasIntroVideo && 'col-lg-6'} ${!hasDescription && 'd-none'}`}>
                            <div className='h3 fw-600 pb-2'>Giới thiệu</div>
                            <div dangerouslySetInnerHTML={{ __html: author?.description }}>

                            </div>
                        </div>

                        <div className={`col-12 col-lg-6  ${!hasIntroVideo && 'd-none'}`}>
                            <div className='w-100 mt-3 position-relative' style={{ paddingTop: '56%' }}>
                                <div className='position-absolute w-100 h-100 top-0'>
                                    <ReactPlayer url={author?.introVideo} className='w-100 h-100' width='100%' height='100%' />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <section className="row mt-3">
                    <section className="col-12">
                        <div className='h3'>Các khóa học của giảng viên <b>{author?.name}</b></div>
                    </section>
                    {sortedCourses.length == 0 &&
                        <NoData message='Hiện chưa có khóa học. Bạn vui lòng quay lại sau.' />
                    }

                    {sortedCourses.map(item =>
                        <div key={item.id} className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
                            <CourseGridItem course={item} />
                        </div>
                    )}
                </section>
            </section>
        </section>
    )
}

const Search = ({ }) => {

    let { filterType, id: catId, name } = useParams()
    let [keyword, setKeyword] = useState("")

    const onSearchClick = () => {
        history.push('/courses/search/' + keyword.trim())
    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            onSearchClick()
            event.preventDefault();
        }
    };

    return (
        <div className="position-relative">
            <input type="text" className="form-control" placeholder="Nhập tên giáo viên hoặc khóa học bạn cần tìm"
                onKeyDown={handleEnter}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)} />
            <button className="btn btn-outline-dark border-0 position-absolute pr-3 py-2 top-0 right-0" onClick={onSearchClick}>
                <FontAwesomeIcon icon={allIcon.faSearch} />
            </button>
        </div>
    )
}

export { TeacherDetail }