import { Link } from "react-router-dom";
import { UrlHelpers } from "../helpers";

const BaseLink = ({ children, to, className, target, title }) => {
    let isAbsolute = UrlHelpers.isAbsoluteUrl(to)

    return (
        <>
            {isAbsolute ?
                <a href={to} target={target} className={className} title={title}>{children}</a> :
                <Link to={to} target={target} className={className} title={title}>{children}</Link>
            }
        </>
    )
};

export default BaseLink