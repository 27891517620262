import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HomeFeatured, HomeBanner, HomeCallToAction, HomeShortFeature } from "./index";
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { KOCImage } from "../shared/KocImage";
import { HomeReview } from '../home/HomeReview';
import { Carousel } from "react-responsive-carousel";
import { ArrayHelpers } from "../helpers";

const founders = [
    {
        name: 'Nguyễn Thị Hường',
        avatar: 'assets/images/Huongnt.jpg'
    },
    {
        name: 'Nguyễn Thị Phương Thanh',
        avatar: 'assets/images/Thanhntp.jpg'
    },
    {
        name: 'Nguyễn Thế Phong',
        avatar: 'assets/images/Phongnt3.jpg'
    },
    {
        name: 'Phạm Chí Kiên',
        avatar: 'assets/images/Kienpc.png'
    },
    {
        name: 'Trần Dũng',
        avatar: 'assets/images/Tran Dung.jpg'
    }
]

const AboutUs = () => {

    useEffect(() => {
        document.body.classList.add('home');
        return () => {
            document.body.classList.remove('home');
        };
    }, [])

    window.gtag('event', 'page_view', {
        page_location: window.location
    })

    const partners = [
        { image: '/assets/images/vmcvietnam.png', name: '' },
        { image: '/assets/images/hotkid.png', name: '' },
        { image: '/assets/images/bke.png', name: '' },
        { image: '/assets/images/9talk.png', name: '' },
    ]

    var pages = ArrayHelpers.arrayToChunks(partners, 6)

    return (
        <div className='w-100'>
            {/* <section className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
                <section className="container">
                    <section className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Về chúng tôi</h1>
                            <div className="h3 text-uppercase fw-400" >Trang chủ / Về chúng tôi</div>
                        </div>
                    </section>
                </section>
                <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div>
            </section> */}
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-6">
                        <div className='course-image position-relative' style={{ paddingTop: '50%' }}>
                            <div className="position-absolute top-0 left-0 w-100 h-100">
                                <KOCImage className='w-100 h-100 objectfit-cover rounded-10px img-flip' src='/assets/images/about-image.png' />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="text-dark-orange h5 fw-600">Xin chào đến với Tambook</div>
                        <div className="h2 fw-600 mt-3">THAM GIA LỚP HỌC VÀ  NÂNG CẤP KỸ NĂNG CỦA BẠN</div>
                        <div className="h6 mt-2">Tambooks mang đến cho bạn cơ hội tiếp cận tri thức ở mọi lúc, mọi nơi; nâng cấp kỹ năng về đa dạng các chủ đề liên quan đến chăm sóc sức khỏe, nuôi dạy con, yoga trị liệu... Mang lại giá trị cộng hưởng và tri thức cho cộng đồng.</div>
                        <div className="btn btn-default bg-dark-orange px-3 h5 text-white fw-600 mt-3 py-3 rounded-8px">THAM GIA LỚP HỌC</div>
                    </div>

                    <div className="col-12 text-center px-0 mt-5">
                        <div className="h2 fw-600">CHÚNG TÔI CÓ GÌ?</div>
                        <div className="row">
                            <div className="col-md-3 col-6">
                                <div className="d-flex flex-column justify-content-center shadow-lg py-3 rounded-10px border">
                                    <div>
                                        <img src='/assets/images/graduated.png' className="h-60px" />
                                    </div>
                                    <div className="h2 fw-600 mb-0 mt-2">500.000+</div>
                                    <div className="h5 text-orange fw-500 mb-0">Học viên</div>
                                </div>
                            </div>

                            <div className="col-md-3 col-6">
                                <div className="d-flex flex-column justify-content-center shadow-lg py-3 rounded-10px border">
                                    <div>
                                        <img src='/assets/images/female.png' className="h-60px" />
                                    </div>
                                    <div className="h2 fw-600 mb-0 mt-2">1.000+</div>
                                    <div className="h5 text-orange fw-500 mb-0">Giảng viên</div>
                                </div>
                            </div>

                            <div className="col-md-3 col-6">
                                <div className="d-flex flex-column justify-content-center shadow-lg py-3 rounded-10px border">
                                    <div>
                                        <img src='/assets/images/storytelling.png' className="h-60px" />
                                    </div>
                                    <div className="h2 fw-600 mb-0 mt-2">2.000+</div>
                                    <div className="h5 text-orange fw-500 mb-0">Khóa học</div>
                                </div>
                            </div>

                            <div className="col-md-3 col-6">
                                <div className="d-flex flex-column justify-content-center shadow-lg py-3 rounded-10px border">
                                    <div>
                                        <img src='/assets/images/handshake.png' className="h-60px" />
                                    </div>
                                    <div className="h2 fw-600 mb-0 mt-2">100+</div>
                                    <div className="h5 text-orange fw-500 mb-0">Đối tác</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 text-center px-0 mt-5 text-uppercase">
                        <div className="h2 fw-600">Trải nghiệm phương pháp <br /> học tập hiện đại</div>
                        <div className="row mt-5 position-relative">
                            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
                                học mọi lúc mọi nơi
                            </div>
                            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
                                <img src="/assets/images/earth.png" className="h-90px" />
                            </div>
                        </div>
                        <div className="row flex-row-reverse mt-4 position-relative">
                            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
                                tính ứng dụng cao
                            </div>
                            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
                                <img src="/assets/images/mobile.png" className="h-90px" />
                            </div>
                        </div>
                        <div className="row mt-4 position-relative">
                            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
                                tối ưu chi phí
                            </div>
                            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
                                <img src="/assets/images/money.png" className="h-90px" />
                            </div>
                        </div>
                        <div className="row flex-row-reverse mt-4 position-relative">
                            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
                                tương tác giảng viên
                            </div>
                            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
                                <img src="/assets/images/interact.png" className="h-90px" />
                            </div>
                        </div>
                        <div className="row mt-4 position-relative">
                            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
                                hỗ trợ 24/7
                            </div>
                            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
                                <img src="/assets/images/customer.png" className="h-90px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeReview />
            <section className="py-5">
                <div className="h2 fw-600 text-center text-uppercase">đối tác của chúng tôi</div>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-12">
                            <Carousel showArrows={true} showThumbs={false} showIndicators={true} showStatus={false} autoPlay={false}>
                                {pages.map((page, index) =>
                                    <div className="w-100 d-flex" key={index}>
                                        {page.map((item, index2) => (
                                            <div className="w-100 px-4 d-flex align-items-center" key={index2}>
                                                <div>
                                                    <KOCImage className='h-100px objectfit-cover' src={item.image} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export { AboutUs }