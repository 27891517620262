import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams, useRouteMatch } from "react-router-dom";
import { Avatar, NoData } from "../shared";
import { CourseGridItem, CourseGridItem2 } from './CourseGridItem';
import { bannerService, courseService } from "../services/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { connect, useDispatch, useSelector } from 'react-redux';
import { history, NumberHelpers, UrlHelpers } from '../helpers';
import { CModal, CModalBody, CModalHeader, CNav, CNavItem } from '@coreui/react';
import { useQuery } from 'react-query';
import { addPopupAction } from '../reducers/popup.reducer';
import { userActions } from '../actions';
import { KOCImage } from '../shared/KocImage';
import classNames from 'classnames';
import moment from 'moment';

const FILTER_TYPE = {
    NEWEST: 'all',
    FEATURED: 'featured',
    HOT: 'hot',
    CATEGORY: 'categories',
    TEACHER: 'teacher',
    SEARCH: 'search'
}

const SHORT_TYPE = {
    Default: "default",
    Name: "name",
    Newest: "newest",
    PriceASC: "price-asc",
    PriceDESC: "price-desc",
}

const SHORT_FUNC = {
    "default": (a, b) => { return 1; },
    "name": (a, b) => { return a.name.localeCompare(b.name); },
    "newest": (a, b) => { return -a.id + b.id; },
    "price-asc": (a, b) => { return a.price - b.price; },
    "price-desc": (a, b) => { return -a.price + b.price; },
}

const MyCourses = () => {
    window.gtag('event', 'page_view', {
        page_location: window.location
    })

    let authentication = useSelector(r => r.authentication);
    let { userInfomation } = authentication;

    let myCourses = useSelector(r => r.myCourses) ?? []
    let { courses } = myCourses
    courses = courses ?? []
    let [courseDetail, setCourseDetail] = useState(null)

    useEffect(() => {
        userActions.getMyCourses();
    }, []);

    useEffect(() => {
        if (!authentication.loggedIn) {
            history.login()
        }
    }, [authentication]);

    return (
        <section className="w-100">
            {/* <section className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
                <section className="container">
                    <section className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Khóa học</h1>
                            <div className="h3 text-uppercase fw-400" >Trang chủ / Khóa học</div>
                        </div>
                    </section>
                </section>
                <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div>
            </section> */}
            <section className="container py-5">

                <div className='row'>
                    <div className='col-12'>
                        <CNav variant="tabs" role="tablist" className='border-0 bg-light-pink rounded-10px py-2 pl-3'>
                            <CNavItem className='bg-transparent py-2'>
                                <Link replace
                                    className={classNames(`px-2 py-2 rounded-8px text-decoration-none bg-orange text-white`)}
                                    to='/my-courses'>
                                    <span className=''>KHÓA HỌC CỦA TÔI</span>
                                </Link>
                            </CNavItem>

                            <CNavItem className='bg-transparent py-2'>
                                <Link replace
                                    className={classNames(`ml-2 px-2 py-2 rounded-8px text-decoration-none text-black border border-orange`)}
                                    to='/my-certs'>
                                    <span className=''>CHỨNG CHỈ CỦA TÔI</span>
                                </Link>
                            </CNavItem>
                            <CNavItem className='bg-transparent py-2'>
                                <Link replace
                                    className={classNames(`ml-2 px-2 py-2 rounded-8px text-decoration-none text-black border border-orange`)}
                                    to='/user/rank'>
                                    <span className=''>XẾP HẠNG</span>
                                </Link>
                            </CNavItem>
                        </CNav>
                    </div>
                </div>

                {/* <div className='row mt-2'>
                    <div className='col-12'>
                        <div className="text-uppercase mb-3 h2 fw-500">Khóa học của tôi</div>
                    </div>
                </div> */}
                <section className="row mt-4">
                    {courses.length == 0 &&
                        <div className='col-12'>
                            <NoData message='Hiện chưa có khóa học. Bạn vui lòng quay lại sau.' />
                            <div className='d-flex justify-content-center'>
                                <Link to='/my-courses' className='btn btn-success mt-2'>Tất cả khóa học</Link>
                            </div>
                        </div>
                    }
                    {courses.map((item, index) =>
                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 py-2">
                            <MyCourseItem item={item} setCourseDetail={setCourseDetail}/>
                        </div>
                    )}
                </section>
            </section>
        </section>
    )
}

const Search = ({ }) => {

    let { filterType, id: catId, name } = useParams()
    let [keyword, setKeyword] = useState("")

    const onSearchClick = () => {
        history.push('/courses/search/' + keyword.trim())
    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            onSearchClick()
            event.preventDefault();
        }
    };

    return (
        <div className="position-relative">
            <input type="text" className="form-control form-control-lg" placeholder="Tìm khóa học và giảng viên"
                onKeyDown={handleEnter}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)} />
            <button className="btn btn-outline-dark border-0 position-absolute pr-3 py-2 top-0 right-0" onClick={onSearchClick}>
                <FontAwesomeIcon icon={allIcon.faSearch} />
            </button>
        </div>
    )
}

const MyCourseItem = ({ item, setCourseDetail }) => {
    let { course, userCourse } = item
    const courseDetailPath = UrlHelpers.createLearningUrl(item.course.slug, item.course.id)
    let avgStar = course.totalRate == 0 ? 0 : Math.round(course.totalStar / course.totalRate * 10) / 10
    let totalPoint = userCourse.completedPoint
    let medalIndex = course.medals?.findIndex(r => totalPoint >= r.point)
    let hasMedal = course.medals?.length > 0
    const IsExpiredTime = item?.userCourse?.expiredTime ? moment(item?.userCourse?.expiredTime) < moment() : false
    const HasExpiredTime = !!item?.userCourse?.expiredTime
    const SoNgayHocConLai = item?.userCourse?.expiredTime ? moment(item?.userCourse?.expiredTime).diff(moment(), 'day') : 0
    return (
        <div className='w-100 h-100 px-3 py-3 border border-orange rounded-15px'>
            <div className='course-wrapper h-100 overflow-hidden d-flex flex-column'>
                <div className='course-image position-relative' style={{ paddingTop: '50%' }}>
                    <div className="position-absolute top-0 left-0 w-100 h-100">
                        <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' src={course.thumbnailImage} />
                    </div>
                </div>
                <div className="course-info pt-2 pb-2 flex-grow-1">
                    <div className="course-author d-flex align-items-center">
                        <Avatar src='' className='w-60px h-60px' />
                        <div className="ml-2">
                            <span className="bg-light-pink px-3 py-1 rounded-lg text-orange text-line-1">{course.category?.name}</span>
                            <span className="h6 fw-600 text-dark">
                                {course.authorName ?? 'admin'}
                            </span>
                        </div>
                    </div>
                    <div className="course-title text-o-ellipsis text-line-2 mt-2">
                        <Link to={courseDetailPath} className='text-black stretched-link' title={course.name}>
                            <span className="h4">{course.name}</span>
                        </Link>
                    </div>

                    {hasMedal &&
                        <span className='position-absolute mx-auto text-center h4' style={{ top: 5, right: 5 }}
                            onClick={() => setCourseDetail(item)}>
                            {medalIndex == 0 &&
                                <FontAwesomeIcon title='Huy chương tím' className='text-purple' icon={allIcon.faAward} size='2x' />
                            }

                            {medalIndex == 1 &&
                                <FontAwesomeIcon title='Huy chương vàng' className='text-gold' icon={allIcon.faAward} size='2x' />
                            }

                            {medalIndex == 2 &&
                                <FontAwesomeIcon title='Huy chương bạc' className='text-silver' icon={allIcon.faAward} size='2x' />
                            }

                            {medalIndex == -1 &&
                                <FontAwesomeIcon className='text-dark opacity-20' icon={allIcon.faAward} size='2x' />
                            }
                        </span>
                    }

                    {/* <div className='mt-2 w-100'>
                        <Link to={courseDetailPath}
                            className='btn btn-link border-0 rounded-pill float-right'>Học tiếp</Link>
                    </div> */}

                </div>
                <div className=''>
                    <div className="d-flex justify-content-between mt-4">
                        <div className="h5">
                            <FontAwesomeIcon icon={allIcon.faUser} className='text-orange' />
                            <span className="fw-600 text-dark ml-1">{NumberHelpers.toDefautFormat(course.registered)}</span>
                        </div>

                        <div className="h5">
                            <span className="fw-600 text-dark mr-1">{avgStar}</span>
                            <FontAwesomeIcon icon={allIcon.faStar} className='text-orange' />
                        </div>
                    </div>
                    <div className='pt-2 h6 text-warning mt-1'>{item.userCourse.progress}% hoàn thành</div>
                    <div className="w-100 h-5px rounded-pill bg-light overflow-hidden align-self-end">
                        <div className="h-5px bg-warning" style={{ width: `${item.userCourse.progress}%` }}></div>
                    </div>
                </div>
                {HasExpiredTime 
                ? (
                    <div className='text-danger text-bold'>
                        {IsExpiredTime ?  "KHOÁ HỌC ĐÃ HẾT HẠN" : (SoNgayHocConLai > 0 ? `Còn lại ${SoNgayHocConLai} ngày`: `Hết hạn lúc ${moment(item?.userCourse?.expiredTime).format('DD-MM-YYYY HH:mm:ss')}`)}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export { MyCourses }