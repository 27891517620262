import APICONFIGS from '../admin-api-configs.json'
import { UrlHelpers, customFetch, authHeader, jsonHeader } from '../../helpers'
import QueryString from 'qs'

export const classService = {
  getClasses,
  getClassesByCodes,
  getClassById,
  createClass,
  updateClass,
  removeClass,
  getMembersByClassId,
  getMemberDetailInClass,
  updateUserClass,
  importStudentsToClass,
  addStudentsToClass,
  removeMembersFromClass,
  getLessonsByClassId,
  removeLessonsFromClass,
  getClassLessonById,
  createClassLesson,
  updateClassLesson,
  getUserClassLessonsByClassId,
  getUserClassLessonsByLessonId,
  createUserClassLesson,
  removeUserClassLesson,
  getNotesOfMemberInClass,
  createUserClassNote,
  updateUserClassNote,
  removeUserClassNote,
  getBCHV,
  getBCCL,
  changeStateOfMembersInClass,
}

function getClasses(queryObject = { page: 1, pageSize: 100 }) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes)
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getClassesByCodes(codes){
  let requestOptions = {
      method: 'POST',
      cache: 'no-cache',
      headers: authHeader(jsonHeader()),
      body: JSON.stringify(codes)
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, 'find-by-code');
  return customFetch(apiUrl, requestOptions)
      .then(res => res);
}

function getClassById(classId) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes)
  apiUrl = UrlHelpers.combine(apiUrl, classId)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function createClass(model) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateClass(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function removeClass(classId) {
  let requestOptions = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
  }

  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, classId)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getMembersByClassId(classId, queryObject = { page: 1, pageSize: 100 }) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.Classes,
    classId,
    'members',
  )
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function importStudentsToClass({classId, usernames}) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify({classId, usernames}),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, 'import-members')
  return customFetch(apiUrl, requestOptions).then((res) => res)
}


function addStudentsToClass({ classId, userIds }) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify({ classId, userIds }),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, 'add-students')
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function removeMembersFromClass({ classId, userIds }) {
  let requestOptions = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify({ classId, userIds }),
  }

  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, 'remove-users')
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getLessonsByClassId(classId, queryObject = { page: 1, pageSize: 100 }) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.Classes,
    classId,
    'lessons',
  )
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function removeLessonsFromClass({ classId, classLessonIds }) {
  let requestOptions = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify({ classId, classLessonIds }),
  }

  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, 'remove-lessons')
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getClassLessonById(lessonId) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassLessons)
  apiUrl = UrlHelpers.combine(apiUrl, lessonId)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function createClassLesson(model) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassLessons)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateClassLesson(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassLessons)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getUserClassLessonsByClassId(classId, queryObject = { page: 1, pageSize: 100 }) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.Classes,
    classId,
    'user-class-lessons',
  )
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getUserClassLessonsByLessonId(lessonId){
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.ClassLessons,
    lessonId,
    'users',
  )
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function createUserClassLesson(model) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.UserClassLessons)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function removeUserClassLesson({ classLessonId, userId }) {
  let requestOptions = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify({ classLessonId, userId }),
  }

  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.UserClassLessons)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getMemberDetailInClass(classId, memberId){
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, classId, 'members', memberId)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateUserClass(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassMembers)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getNotesOfMemberInClass(classId, memberId, queryObject = { page: 1, pageSize: 100 }) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, classId, 'members', memberId, 'notes')
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function createUserClassNote(model) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassNotes)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateUserClassNote(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassNotes)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function removeUserClassNote(noteId) {
  let requestOptions = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
  }

  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassNotes, noteId)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getBCHV(queryObject = {v: 'v1'}) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassReports, "bchv")
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getBCCL(queryObject = {v: 'v1'}) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }

  let queryParams = QueryString.stringify(queryObject, { allowDots: true, encode: false })
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.ClassReports, "bccl")
  apiUrl = `${apiUrl}?${queryParams}`
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function changeStateOfMembersInClass(classId, {userIds, state}){
  let requestOptions = {
      method: 'POST',
      cache: 'no-cache',
      headers: authHeader(jsonHeader()),
      body: JSON.stringify({userIds, state})
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Classes, classId, 'members', 'change-state');
  return customFetch(apiUrl, requestOptions)
      .then(res => res);
}