import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useParams } from "react-router-dom";
import { NoData } from "../shared";
import { CourseGridItem, CourseGridItem2 } from './CourseGridItem';
import { bannerService, courseService } from "../services/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { connect, useDispatch } from 'react-redux';
import { history } from '../helpers';
import { CModal, CModalBody, CModalHeader, CPagination } from '@coreui/react';
import { useQuery } from 'react-query';
import { addPopupAction } from '../reducers/popup.reducer';
import { useSearchParams } from '../shared/useSearchParams';
import { useQueryParam, NumberParam, StringParam, withDefault } from 'use-query-params';

const FILTER_TYPE = {
    NEWEST: 'all',
    FEATURED: 'featured',
    HOT: 'hot',
    CATEGORY: 'categories',
    TEACHER: 'teacher',
    SEARCH: 'search'
}

const SHORT_TYPE = {
    Default: "default",
    Name: "name",
    Newest: "newest",
    PriceASC: "price-asc",
    PriceDESC: "price-desc",
}

const SHORT_FUNC = {
    "default": (a, b) => { return 1; },
    "name": (a, b) => { return a.name.localeCompare(b.name); },
    "newest": (a, b) => { return -a.id + b.id; },
    "price-asc": (a, b) => { return a.price - b.price; },
    "price-desc": (a, b) => { return -a.price + b.price; },
}

const CoursesZoom = () => {
    const dispatch = useDispatch()
    let { filterType, id: catId, name } = useParams()

    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
    const [sortType, setSortType] = useQueryParam('sort', withDefault(StringParam, SHORT_TYPE.default))

    let [categories, setCategories] = useState([])
    // let [sortType, setSortType] = useState(SHORT_TYPE.default)
    let [addAccessPopup, setAddAccessPopup] = useState(false)

    filterType = filterType ?? 'all'

    const sortTypeChanged = (e) => {
        setSortType(e.target.value);
    }

    const { data: authorsResult } = useQuery("courses-authors", () => courseService.getAuthors(), { staleTime: 600000 });
    const authors = authorsResult?.data ?? []

    const { data: bannersResult, isSuccess } = useQuery(['banners'], () => { return bannerService.getAll() }, { staleTime: Infinity })
    let banners = bannersResult?.data ?? [];
    banners = banners.filter(r => r.active && r.type == 'popup' && r.event == 'VIEWCOURESLIST')
    let hasBanner = banners.length > 0

    const { data: coursesResult } = useQuery(["coursesZoom", filterType, sortType, catId, page, name], () => getCourses(), { staleTime: 600000 });
    const courses = coursesResult?.data?.items ?? []
    const total = coursesResult?.data?.total ?? 0
    let pageSize = 12
    let pageCount = Math.ceil(total / pageSize);

    const getCourses = () => {
        let promise;

        let pagingModel = { page: page, pageSize: pageSize }
        let filtersModel = [{ field: 'active', value: 'true', operator: '==' }, { field: 'type', value: 'ZOOM', operator: 'Contains' }];
        let sortModel = { sort: 'createdDateTime', direction: 'asc' }

        switch (filterType) {
            case FILTER_TYPE.FEATURED:
                filtersModel.push({ field: 'featured', value: 'true', operator: '==' })
                break;
            case FILTER_TYPE.CATEGORY:
                filtersModel.push({ field: 'categoryId', value: catId, operator: '==' })
                break;
            case FILTER_TYPE.TEACHER:
                filtersModel.push({ field: 'authorId', value: catId, operator: '==' })
                break;
            case FILTER_TYPE.SEARCH:
                filtersModel.push({ field: 'keyword', value: name, operator: '==' })
                // promise = courseService.getCoursesByKeyword(name);
                break;
        }

        switch (sortType) {
            case SHORT_TYPE.Default:
                break;
            case SHORT_TYPE.Name:
                sortModel = { sort: 'name', direction: 'asc' }
                break;
            case SHORT_TYPE.Newest:
                sortModel = { sort: 'createdDateTime', direction: 'desc' }
                break;
            case SHORT_TYPE.PriceASC:
                sortModel = { sort: 'price', direction: 'asc' }
                break;
            case SHORT_TYPE.PriceDESC:
                sortModel = { sort: 'price', direction: 'desc' }
                break;
        }

        promise = courseService.filter(pagingModel, filtersModel, sortModel)

        return promise
    }

    const getCategories = () => {
        courseService.getCategories()
            .then(res => {
                setCategories(res.data)
            })
    }

    useEffect(() => {
        if (!addAccessPopup && isSuccess) {
            if (banners.length > 0) {
                dispatch(addPopupAction(banners))
            }
            setAddAccessPopup(true)
        }
    }, [isSuccess])

    useEffect(getCategories, [])

    // useEffect(() => {
    //     getCourses()
    // }, [filterType, catId, name])

    // let sortedCourses = [...courses].sort(SHORT_FUNC[sortType]);
    let sortedCourses = courses

    return (
        <section className="w-100">
            <div className='d-flex flex-column bg-light-pink pb-3'>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Khóa học ZOOM</h1>
                        </div>
                    </div>
                </div>
                {/* <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div> */}
            </div>
            <section className="container py-5">
                <div className='row'>
                    <div className='col-12'>
                        <Search />
                    </div>
                </div>
                <section className="row mt-4">
                    <section className="col-md-4 col-lg-3 d-none d-md-block">
                        <ul className='list-unstyled'>
                            <li className='mt-2'>
                                <Link to='/courses-zoom' className={`h5 fw-600 ${filterType == FILTER_TYPE.NEWEST ? 'text-orange interact-none' : 'text-black text-hover-orange'}`}>Tất cả</Link>
                            </li>
                            {/* <li className='mt-2'>
                                <Link to='/courses/hot' className={`h5 fw-600 ${filterType == FILTER_TYPE.HOT ? 'text-orange interact-none' : 'text-black text-hover-orange'}`}>Hot</Link>
                            </li> */}
                            <li className='mt-3'>
                                <Link to='/courses-zoom/featured' className={`h5 fw-600 ${filterType == FILTER_TYPE.FEATURED ? 'text-orange interact-none' : 'text-black text-hover-orange'}`}>Nổi bật</Link>
                            </li>

                            {categories.filter(r => r.level == 0).map(item =>
                                <CategoryGroup key={item.id}
                                    item={item}
                                    categories={categories.filter(r => r.parent == item.id)}
                                    currentCatId={catId}
                                />
                            )}




                            {authors.length > 0 &&
                                <TeacherList teachers={authors} name={name} id={catId} />
                            }
                        </ul>
                    </section>
                    <section className="col-md-8 col-lg-9 p-0">
                        <div className="row mb-3">
                            <div className='col-12 row justify-content-between'>
                                <div className='col-12 col-sm-6 p-0 mb-2'>
                                    {/* <Search /> */}
                                </div>
                                <div className="col-12 col-sm-5 col-md-4 p-0">
                                    <label className="select w-100" htmlFor="slct">
                                        <select value={sortType} onChange={sortTypeChanged} className='form-control'>
                                            <option value="default">Sắp xếp</option>
                                            <option value="name">A-Z</option>
                                            <option value="newest">Mới nhất</option>
                                            <option value="price-asc">Giá thấp - cao</option>
                                            <option value="price-desc">Giá cao - thấp</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <section className="row">
                            {sortedCourses.length == 0 &&
                                <div className='col-12'>
                                    <NoData message='Hiện chưa có khóa học. Bạn vui lòng quay lại sau.' />
                                </div>
                            }

                            {sortedCourses.map(item =>
                                <div key={item.id} className="col-12 col-sm-6 col-md-6 col-lg-4 py-2">
                                    <CourseGridItem course={item} />
                                </div>
                            )}
                        </section>

                        <div className='row d-flex justify-content-center mt-3'>
                            <CPagination pages={pageCount} activePage={page} hidden={pageCount < 2}
                                onActivePageChange={(page) => {
                                    if (page == 0) return
                                    // history.push(`?page=${page > 1 ? page : 1}`);
                                    setPage(page > 1 ? page : 1)
                                    window.scrollTo(0, 0);
                                }}></CPagination>
                        </div>

                    </section>
                </section>
            </section>
        </section>
    )
}

const CategoryGroup = props => {
    return (
        <li className='mt-3'>
            <div className={`h5 fw-600 text-black`}>{props.item.name}</div>
            {props.categories.length > 0 &&
                <ul className='list-unstyled ml-3'>
                    {props.categories.map(item =>
                        <CategoryItem key={item.id}
                            item={item}
                            currentCatId={props.currentCatId}
                        />
                    )}
                </ul>
            }
        </li>
    );
}

const CategoryItem = ({ item, currentCatId }) => {
    let isActive = currentCatId == item.id
    let className = 'CourseSideBar_active'
    return (
        <li className='py-2 border mt-1 px-2'>
            <Link
                className={`h6 ${isActive ? 'text-orange interact-none' : 'text-black text-hover-orange'}`}
                to={"/courses-zoom/categories/" + item.slug + "/" + item.id}>{item.name}</Link>
        </li>
    );
}

const Search = ({ }) => {

    let { filterType, id: catId, name } = useParams()
    let [keyword, setKeyword] = useState("")

    const onSearchClick = () => {
        history.push('/courses-zoom/search/' + keyword.trim())
    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            onSearchClick()
            event.preventDefault();
        }
    };

    return (
        <div className="position-relative">
            <input type="text" className="form-control form-control-lg" placeholder="Tìm khóa học và giảng viên"
                onKeyDown={handleEnter}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)} />
            <button className="btn btn-outline-dark border-0 position-absolute pr-3 py-2 top-0 right-0" onClick={onSearchClick}>
                <FontAwesomeIcon icon={allIcon.faSearch} />
            </button>
        </div>
    )
}

const TeacherList = ({ teachers, name, id }) => {
    let [show, setShow] = useState(false)
    let [filterText, setFilterText] = useState('')
    return (
        <li className="mt-3">
            <div className='h5 fw-600 text-black d-flex justify-content-between'>
                <div>Giảng viên</div>
                <button className='btn btn-link p-0' onClick={() => { setShow(true) }}>xem thêm</button>
            </div>
            <ul className='list-unstyled ml-3'>
                {teachers.slice(0, 6).map(item =>
                    <li key={item.id} className='py-2 border mt-1 px-2'>
                        <Link
                            className={`h6 ${item.id == id ? 'text-orange interact-none' : 'text-black text-hover-orange'}`}
                            to={"/courses-zoom/teacher/" + item.slug + '/' + item.id}>{item.name}</Link>
                    </li>
                )}
            </ul>
            <div className='position-relative'>
                <CModal show={show} onClose={() => { setShow(false) }} size='xl' scrollable>
                    <CModalHeader closeButton>Danh sách giảng viên</CModalHeader>
                    <CModalBody>
                        <div className='row'>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={allIcon.faSearch} /></span>
                                </div>
                                <input type="search"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                    className="form-control"
                                    placeholder="Nhập tên giảng viên" />
                            </div>
                        </div>
                        <div className='row'>
                            {teachers.filter(r => r.name.toUpperCase().includes(filterText.toUpperCase())).map(item =>
                                <div key={item.id} className='col-4 col-lg-4'>
                                    <Link onClick={() => setShow(false)}
                                        className='btn btn-link text-left'
                                        to={"/courses-zoom/teacher/" + item.slug + '/' + item.id}>{item.name}</Link>
                                </div>
                            )}
                        </div>

                    </CModalBody>
                </CModal>
            </div>
        </li>
    )
}

export { CoursesZoom }