import 'core-js/actual';
import { hot } from 'react-hot-loader';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { store, history, persistor } from './helpers';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { } from "moment/locale/vi";
import { Router, Switch, Route, Link, NavLink, BrowserRouter } from "react-router-dom";
import { AlertComponent, Loading, Modal } from './shared';
import { userService } from "./services";
// import { AdminApp } from "./admin";
import './admin/scss/style.scss';
import './assets/scss/theme.scss';
import { QueryParamProvider } from 'use-query-params';
import { pdfjs } from 'react-pdf';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import moment from 'moment';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Main from './Main';
import { GoogleOAuthProvider } from '@react-oauth/google';
window.React = React
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
moment.updateLocale('vi', {
  weekdays: [
    "Chủ nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"
  ]
});

window.moment = moment

const logLevels = {
  Trace: 0,
  Debug: 1,
  Information: 2,
  Warning: 3,
  Error: 4,
  Critical: 5,
  None: 6,
}

if (!String.format) {
  String.format = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
        ;
    });
  };
}

String.prototype.format = function (o) {
  return String.format(this, o);
};

Array.prototype.last = function () {
  if (this.length == 0) return null;
  return this[this.length - 1];
}

// const AdminApp = React.lazy(() => import('./admin/AdminApp'));

// define a new console
var console = (function (oldCons) {
  return {
    log: function (text, ...optionalParams) {
      if (window.console.logLevel <= logLevels.Debug)
        oldCons.log(text, ...optionalParams);
    },
    info: function (text, ...optionalParams) {
      if (window.console.logLevel <= logLevels.Information)
        oldCons.info(text, ...optionalParams);
    },
    warn: function (text, ...optionalParams) {
      if (window.console.logLevel <= logLevels.Warning)
        oldCons.warn(text, ...optionalParams);
    },
    error: function (text, ...optionalParams) {
      if (window.console.logLevel <= logLevels.Error)
        oldCons.error(text, ...optionalParams);
    }
  };
}(window.console));

//Then redefine the old console
window.console = console;
window.console.logLevel = logLevels.Debug

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <QueryParamProvider ReactRouterRoute={Route}>

            <Main />
            
          </QueryParamProvider>
        </React.Suspense>
      </Router >
    </PersistGate>
  </Provider>,
  document.getElementById('root')
  // document.getElementsByTagName("body")[0]
);


// ReactDOM.render(
//   <React.StrictMode>
//     <div>asdaasdas</div>
//   </React.StrictMode>
//   , document.getElementById('root')
// )
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
