const LessonExpiredTime = ({ course }) => {
  return (<>
    <div className='w-100 h-100 d-flex align-items-center text-success justify-content-center'>
      <img src={course?.thumbnailImage || "/assets/images/no-image-available.png"} className='position-absolute w-100 h-100 objectfit-cover' alt='Error' />
      <div className="w-50 h-25 bg-light-pink opacity-75 d-flex align-items-center justify-content-center">
        <span className="fw-900 text-danger">Khoá học đã hết hạn, vui lòng gia hạn khoá học, hoặc liên hệ bộ phận hỗ trợ</span>
      </div>
    </div>
  </>)
}

export default LessonExpiredTime;