import React, { Suspense, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import { UrlHelpers } from "../helpers/index";
import { courseService, userService } from "../services";
import moment from 'moment';
import { history } from "../helpers";
import { LessonVideoPlayer, LessonList } from './';
import { alertActions } from "../actions";
import Tour from "reactour";
import { LessonType, settingKeysConstant, themeConst } from '../constants';
import { useTheme } from '../helpers/theme.helpers';
import PdfComponent from '../shared/PdfComponent';
import { CModal, CModalBody, CModalHeader, CNav, CNavItem, CTabContent } from '@coreui/react';
import { QuizStatisticsButton } from './QuizStatistics';
import { SurveyButton } from './CourseSurvey';
import { CertificateButton } from './CourseCertificate';
import classNames from 'classnames';
import { useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from '../shared/useSearchParams';
import { useState } from 'react';
import { CourseRating } from './CourseRating';
import LessonExpiredTime from './LessonExpiredTime';
let logo = "/assets/images/logo-white(282x80).png";
const donateMessage = 'Nếu hài lòng với khoá học, dự án chúng tôi rất vui nếu bạn có một chút đóng góp tuỳ tâm để nhiều khoá học tốt hơn nữa được ra đời và đến với mọi người. Bạn có thể chuyển tiền ủng hộ qua số tài khoản hoặc quét mã VNpay, Momo trực tiếp cho người phụ trách dự án này.';
const mobileWidth = 992

const TabsType = {
    contents: 'contents',
    comment: 'comment',
    notes: 'notes',
    announcement: 'announcement',
    liveSchedual: 'live',
    relate: 'relate',
}

const LearningLesson = () => {
    let authentication = useSelector(r => r.authentication);
    let { userInfomation } = authentication;
    let { lessonId, courseId, course: courseSlug, lesson: lessonSlug } = useParams();
    let searchParams = useSearchParams()
    let tab = searchParams.get('tab') ?? ''

    const queryClient = useQueryClient()
    window.queryClient = queryClient
    let [isTourOpen, setTourOpen] = useState(false)
    let [showRating, setShowRating] = useState(false)
    let [showDonate, setShowDonate] = useState(false)

    let playerRef = useRef()
    const { data: courseResult } = useQuery(["course-detail", courseId], () => courseService.getCourseDetail(courseId, true));
    const courseResponse = courseResult?.data

    const { data: courseLessonResult } = useQuery(["course-lessons", courseId], () => courseService.getCourseLessons(courseId));
    const courseProgram = courseLessonResult?.data ?? { chapters: [], lessons: [], userLessons: [] }
    let { chapters, lessons, userLessons } = courseProgram
    let hasCheckpoint = false;
    lessons.forEach((lesson, index, array) => {
        lesson.completed = userLessons.some(r => r.lessonId == lesson.id);
        lesson.locked = hasCheckpoint
        if (!lesson.completed && lesson.checkpoint) {
            hasCheckpoint = true;
        }
    })

    const isRegistered = courseResponse?.userCourse != null
    let course = courseResponse?.course
    let userCourse = courseResponse?.userCourse
    const currentLesson = lessons.find(r => r.id == lessonId)
    const hasCurrentLesson = currentLesson != null
    const currentUrl = UrlHelpers.createLearningUrl(courseSlug, courseId, lessonSlug, lessonId)
    const Auto_Next = localStorage.getItem(settingKeysConstant.AUTO_NEXT_LESSON) != "false";
    const IsExpiredTime = userCourse?.expiredTime ? moment(userCourse?.expiredTime) < moment() : false

    useEffect(() => {
        if (!lessonId) {
            var nextLesson = lessons.find(r => !r.completed);
            if (!nextLesson && lessons.length > 0) {
                nextLesson = lessons[0]
            }

            if (nextLesson) {
                if (window.innerWidth > 992)
                    history.replace(UrlHelpers.createLearningUrl(courseSlug, courseId, nextLesson.slug, nextLesson.id))
                else
                history.replace(UrlHelpers.createLearningUrl(courseSlug, courseId, nextLesson.slug, nextLesson.id, TabsType.contents))
            }
        }
    }, [lessons])

    useEffect(() => {
        if (!authentication.loggedIn) {
            history.login()
        } else {
            userService.addDailyRankPoint()
                .then(res => {
                    if (res.isSuccess) {
                        alertActions.success("Bạn đã được công 1 điểm")
                    } else {
                        
                    }
                })
                .catch(err => console.error(err?.message))
        }
    }, [authentication]);

    const onLessonEnded = async () => {
        let isCurrentComplete = currentLesson.completed;
        if (!isCurrentComplete) {
            let progress = Math.floor((userLessons.length + 1) * 100 / lessons.length);
            userCourse.progress = progress;
            userCourse.completed = progress == 100;
            await courseService.completeLesson(lessonId, progress)
                .then(res => {
                    if (res.isSuccess) {
                        userLessons.push({
                            lessonId: lessonId
                        });
                        currentLesson.completed = true;
                    }
                });


            // tryNextLesson()
        } else {
            // tryNextLesson()
        }

        queryClient.invalidateQueries("course-lessons")
    }

    const getNextLesson = () => {
        let currentIndex = lessons.findIndex(r => r.id == lessonId);
        if (currentIndex == lessons.length - 1) {
            return null;
        }
        let nextLesson = lessons[currentIndex + 1];
        return nextLesson
    }

    const tryNextLesson = () => {
        if (!Auto_Next) return
        let nextLesson = getNextLesson()
        if (nextLesson)
            history.push(UrlHelpers.createLearningUrl(courseSlug, courseId, nextLesson.slug, nextLesson.id, tab))
    }

    return (
        <>
            <div className="w-100" style={{ marginTop: '0px' }}>
                <section className="container-fluid px-0 h-70px">
                    <div className='h-70px w-100 z-index-600 bg-dark-orange' style={{ top: 0 }}>
                        <div className="h-100 w-100 d-flex align-items-center px-2 position-relative text-white">
                            <Link to={UrlHelpers.createCourseDetailUrl(courseSlug, courseId)} className='pl-2'>
                                <div className="text-light" title="Rời khỏi đây">
                                    <FontAwesomeIcon icon={allIcon.faChevronLeft} size='lg' className="" />
                                </div>
                            </Link>
                            {/* <Link className="py-2 pl-4 d-none d-md-block" to='/'>
                                <img src={logo} alt="Tambooks" className='h-50px' />
                            </Link> */}

                            <Link to={'/'}><img src={logo} className='py-2 pl-4 d-none d-md-block h-50px' /></Link>
                            <Link to={'/'}><img src='/assets/images/logo-white(65x50).png' className='ml-2 py-2 pl-4d-block d-md-none h-50px' /></Link>

                            <div className="Header_divider ml-3"></div>
                            <Link to={UrlHelpers.createCourseDetailUrl(courseSlug, courseId)} className="btn btn-link d-none d-lg-block ml-3 h5 pt-2 text-white">{course?.name}</Link>
                            <div className='ml-auto d-flex align-items-center'>
                                <CertificateButton courseId={courseId} />
                                <SurveyButton courseId={courseId} />
                                <QuizStatisticsButton course={course} chapters={chapters} lessons={lessons} />
                                <div className="mr-3 hover-pointer" onClick={() => setTourOpen(true)}>
                                    <FontAwesomeIcon icon={allIcon.faQuestionCircle} className="fa-w-16" />
                                    <span className='d-none d-sm-inline-block ml-1'>Hướng dẫn</span>
                                </div>
                                <div className="mr-3 hover-pointer"
                                    onClick={() => setShowRating(true)}>
                                    <FontAwesomeIcon icon={allIcon.faStar} className="fa-w-6" />
                                    <span style={{ marginLeft: '5px' }} className='d-none d-sm-inline-block ml-1 mr-2'>Đánh giá</span>
                                </div>
                                {/* <div className="mr-3 p-1 px-2 hover-pointer bg-orange text-white"
                                    onClick={() => setShowDonate(true)}>
                                    <FontAwesomeIcon icon={allIcon.faHeart} className="fa-w-6 blinking" />
                                    <span style={{ marginLeft: '5px' }} className='d-none d-sm-inline-block ml-1'>Quyên góp</span>
                                </div> */}
                                {/* <ThemeButton /> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container-fluid px-0">
                    <div className='w-100 d-flex align-items-stretch'>
                        <div className={`flex-grow-1 pb-5`}>
                            <div className='position-relative' style={{ paddingTop: '56.25%' }}>
                                <div className='position-absolute w-100 h-100 top-0 left-0 bg-black rounded-lg'>
                                    {currentLesson?.type === LessonType.VIDEO && 
                                        (IsExpiredTime 
                                            ? <LessonExpiredTime course={course}/> 
                                            : <LessonVideoPlayer url={currentLesson?.videoUrl}
                                                currentLesson={currentLesson}
                                                currentLessonId={currentLesson.id}
                                                onLessonEnded={onLessonEnded}
                                                tryNextLesson={tryNextLesson}
                                                getNextLesson={getNextLesson} />)
                                    }

                                    {currentLesson?.type === LessonType.PDF &&
                                        <div className='video-wrapper ActiveVideo_player'>
                                            {IsExpiredTime
                                                ? <LessonExpiredTime />
                                                : <PdfComponent pdfFile={currentLesson.videoUrl} />}
                                        </div>
                                    }

                                    {!hasCurrentLesson &&
                                        <img src={course?.thumbnailImage} className='w-100 h-100 objectfit-cover' alt='Error'/>
                                    }
                                </div>
                            </div>
                            <section className="container-fluid">
                                <section className="row">
                                    <div className='col-12 mt-3'>
                                        <div className='h3 fw-600'>{course?.name}</div>
                                        {currentLesson != null &&
                                            <div className='h5 font-italic'>{currentLesson.name}</div>
                                        }
                                    </div>
                                    <div className='col-12 mt-3'>
                                        <CNav variant="tabs" role="tablist" className='border-0 bg-light-pink rounded-10px py-2 pl-3'>
                                            <CNavItem className='bg-transparent py-2'>
                                                <Link replace
                                                    className={classNames(`px-2 py-2 rounded-8px ${tab === '' ? 'bg-orange text-white' : 'text-black border border-orange'}`)}
                                                    to={{ pathname: currentUrl, search: '?tab=' }}>
                                                    <span className=''>Tổng quan</span>
                                                </Link>
                                            </CNavItem>

                                            <CNavItem className='bg-transparent py-2 d-lg-none d-block'>
                                                <Link replace
                                                    className={classNames(`ml-2 px-2 py-2 rounded-8px ${tab === TabsType.contents ? 'bg-orange text-white' : 'text-black border border-orange'}`)}
                                                    to={{ pathname: currentUrl, search: `?tab=${TabsType.contents}` }}>
                                                    <span className=''>Bài học</span>
                                                </Link>
                                            </CNavItem>
                                            {/* 
                                            <CNavItem className='bg-transparent py-2'>
                                                <Link replace
                                                    className={classNames(`ml-2 px-2 py-2 rounded-8px ${tab === TabsType.notes ? 'bg-orange text-white' : 'text-black border border-orange'}`)}
                                                    to={{ pathname: currentUrl, search: `?tab=${TabsType.notes}` }}>
                                                    <span className=''>Ghi chú</span>
                                                </Link>
                                            </CNavItem>

                                            <CNavItem className='bg-transparent py-2'>
                                                <Link replace
                                                    className={classNames(`ml-2 px-2 py-2 rounded-8px ${tab === TabsType.relate ? 'bg-orange text-white' : 'text-black border border-orange'}`)}
                                                    to={{ pathname: currentUrl, search: `?tab=${TabsType.relate}` }}>
                                                    <span className=''>Khóa học liên quan</span>
                                                </Link>
                                            </CNavItem> */}
                                        </CNav>
                                    </div>
                                    <div className='col-12 mt-3'>
                                        <CTabContent className='mt-2 rounded-8px bg-light-pink overflow-hidden'>
                                            <div className=''>
                                                {tab === '' &&
                                                    <div className='py-3 pl-3'>
                                                        <div className='h3 fw-600'>Chi tiết khóa học</div>
                                                        <p dangerouslySetInnerHTML={{ __html: course?.description }} className='text-dark'></p>
                                                    </div>
                                                }

                                                {/* {tab === TabsType.relate &&
                                                    <div>
                                                        <div className='h3 fw-600'>Khóa học liên quan</div>
                                                        <LearningRelated />
                                                    </div>
                                                } */}

                                                {tab === TabsType.contents &&
                                                    <div>
                                                        <LessonList chapters={chapters}
                                                            lessons={lessons}
                                                            courseId={courseId}
                                                            currentLesson={currentLesson}
                                                            lessonId={lessonId} />
                                                    </div>
                                                }

                                                {/* {(tab === TabsType.comment && hasCurrentLesson) &&
                                                                <div>
                                                                    <LessonComment currentLesson={currentLesson} active={tab == TabsType.comment} />
                                                                </div>
                                                            }

                                                            {(tab === TabsType.notes && hasCurrentLesson) &&
                                                                <div>
                                                                    <LessonNote currentLesson={currentLesson} lessons={lessons} />
                                                                </div>
                                                            } */}

                                                {/* {tab === TabsType.announcement &&
                                                                <div>
                                                                    <LessonAnnouncement courseId={courseId} />
                                                                </div>
                                                            }

                                                            {tab === TabsType.liveSchedual &&
                                                                <div>
                                                                    <LiveClassTab courseId={courseId} />
                                                                </div>
                                                            } */}
                                            </div>
                                        </CTabContent>
                                    </div>
                                </section>
                            </section>
                        </div>
                        <div className={`w-30pc bg-light-pink d-none d-lg-block`}>
                            <div className="w-100">
                                {/* <header className="Playlist_header" style={progressStyle}>
                                            <h1 className="Playlist_heading">{course?.name}</h1>
                                            <div className="Playlist_courseInfo">
                                                <p className="Playlist_description">Hoàn thành <strong>{lessons?.filter(r => r.completed).length}</strong>/<strong>{lessons?.length}</strong> bài học (<strong>{progressStyle['--progress']}</strong>)</p>
                                                <div className="Playlist_closeBtn Playlist_visibleDesktop" title="Thu nhỏ sách bài học" onClick={() => this.setState({ showDesktopLessons: false })}>
                                                    <svg className="Playlist_icon" width="18" height="18" viewBox="0 0 32 32">
                                                        <path d="M2,14.5h18.4l-7.4-7.4c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l10,10c0.6,0.6,0.6,1.5,0,2.1l-10,10c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l7.4-7.4H2c-0.8,0-1.5-0.7-1.5-1.5C0.5,15.3,1.2,14.5,2,14.5z M28,3.5C28,2.7,28.7,2,29.5,2S31,2.7,31,3.5v25c0,0.8-0.7,1.5-1.5,1.5S28,29.3,28,28.5V3.5z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </header> */}
                                <LessonList chapters={chapters}
                                    lessons={lessons}
                                    courseId={courseId}
                                    currentLesson={currentLesson}
                                    lessonId={lessonId}
                                    userLessons={userLessons} />
                            </div>
                        </div>

                    </div>
                    {showRating &&
                        <div>
                            <CModal show={showRating} onClose={() => setShowRating(false)}>
                                <CModalHeader closeButton>
                                    Bạn nghĩ sao về khóa học này
                                </CModalHeader>
                                <CModalBody>
                                    <CourseRating course={course} onClose={() => setShowRating(false)} />
                                </CModalBody>
                            </CModal>
                        </div>
                    }

                    {/* {showDonate &&
                        <div>
                            <CModal show={showDonate} onClose={() => setShowDonate(false)}>
                                <CModalHeader closeButton className='h4 fw-600'>
                                    Ủng hộ Tambooks
                                </CModalHeader>
                                <CModalBody>
                                    <div className='h5'>
                                        {course?.donateAccount ?
                                            <span>{course?.donateAccount.description}</span>
                                            :
                                            <span>Nếu hài lòng với khoá học, dự án chúng tôi rất vui nếu bạn có một chút đóng góp tuỳ tâm để nhiều khoá học tốt hơn nữa được ra đời và đến với mọi người.</span>
                                        }
                                    </div>
                                    <div className='w-100'>
                                        {course.donateAccount ?
                                            <div className='course-rating-body'>
                                                <div><span>Ngân hàng: </span><strong className=''>{course.donateAccount.bankName}</strong></div>
                                                <div><span>Số Tài khoản: </span> <strong>{course.donateAccount.accountNumber}</strong></div>
                                                <div><span>Chủ Tài khoản: </span> <strong>{course.donateAccount.owner}</strong></div>
                                            </div> :
                                            <div className='w-100 d-flex justify-content-center mt-3'>
                                                <button className='btn btn-success'
                                                    onClick={() => {
                                                        window.gtag('event', 'donate_click', {
                                                            position: 'learning'
                                                        })
                                                        history.push("/donation");
                                                    }}>
                                                    <FontAwesomeIcon icon={allIcon.faHeart} style={{ marginRight: '8px' }} />Đồng ý
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </CModalBody>
                            </CModal>
                        </div>
                    } */}


                </section>
            </div>

            <Suspense fallback={<React.Fragment />}>
                <Tour
                    onRequestClose={() => setTourOpen(false)}
                    steps={tourConfig}
                    isOpen={isTourOpen}
                    rounded={5}
                    showNumber={false}
                    accentColor={'linear-gradient(to right, #1c8f9e, #5cb7b7)'}
                />
            </Suspense>
        </>
    );
}

const tourConfig = [
    {
        content: 'Chào cậu! Mình là Miu - hướng dẫn viên tại Tambooks, mình sẽ đưa cậu đi thăm quan và giới thiệu cho cậu hiểu rõ hơn về Tambooks nhé. Đi thôi!',
    },
    {
        selector: '.ActiveVideo_wrapper',
        content: 'Đây là khu vực trung tâm của màn hình này, toàn bộ nội dung các bài học như là video, hình ảnh, văn bản sẽ được hiển thị ở đây nhé ^^',
    },
    {
        selector: '.Learning_playListColumn',
        content: 'Tiếp theo là khu vực quan trọng không kém, đây là danh sách các bài học tại khóa này. Cậu sẽ rất thường xuyên tương tác tại đây để chuyển bài học và làm bài tập đấy >_<',
    },
    {
        selector: '.chapter-0',
        content: 'Đây là bài học đầu tiên dành cho cậu, khi học xong bài học này Miu sẽ đánh "Tích xanh" bên cạnh để đánh dấu cậu đã hoàn thành bài học nhé!',
    },
    {
        selector: '.Learning_commentBlock',
        content: 'Và đây là khu vực dành cho việc hỏi đáp, trao đổi trong mỗi bài học. Nếu có bài học nào hay thì cậu bình luận một lời động viên vào đây cũng được nhé. Miu sẽ rất vui và cảm thấy biết ơn đấy <3',
    }
];

const ThemeButton = () => {
    let theme = useSelector(state => state.themeState)
    let [toggleTheme] = useTheme();
    return (
        <div className="mr-3" onClick={toggleTheme}>
            <FontAwesomeIcon icon={theme.name == themeConst.dark ? allIcon.faSun : allIcon.faMoon} className="fa-w-6" />
        </div>
    )
}

export { LearningLesson };