import { useSelector } from "react-redux";
import { userService } from "../services";
import { useForm } from 'react-hook-form';
import { alertActions } from "../actions";

const Contact = () => {
    window.gtag('event', 'page_view', {
        page_location: window.location
    })

    // let [fullName] = useFormField({ value: '', rules: [{ rule: 'required', message: 'Vui lòng nhập thông tin.' }] })
    // let [email] = useFormField({ value: '', rules: [{ rule: 'required', message: 'Vui lòng nhập thông tin.' }, { rule: 'email', message: 'Vui lòng nhập chính xác địa chỉ email.' }] })
    // let [phone] = useFormField({
    //     value: '', rules: [{ rule: 'required', message: 'Vui lòng nhập thông tin.' }], customRule: () => {
    //         if (phone.value && phone.value != '') {
    //             var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    //             if (vnf_regex.test(phone.value) == false) {
    //                 return ['Vui lòng nhập chính xác số điện thoại.'];
    //             }

    //             return [];
    //         }
    //     }
    // })

    // let [form] = useForm([fullName, email, phone])

    const formHook = useForm({})
    const { register, handleSubmit, formState: { errors }, control, getValues, reset } = formHook

    const onsubmit = (e) => {
        var model = {
            fullName: e.fullName,
            email: e.email,
            phone: e.phone,
            note: e.note,
        }

        userService.kocRegister(model)
            .then(res => {
                if (res.isSuccess) {
                    alertActions.success('Cảm ơn bạn đã để lại thông tin. Chúng tôi sẽ liên hệ với bạn trong thời gian gần nhất.')
                    reset({
                        fullName: '',
                        email: '',
                        phone: '',
                        note: '',
                    })
                }
            });
    }

    return (
        <div>
            <div className="w-100">
                {/* <HomeBanner /> */}
                <div id="about-us" className="container py-5">

                    <div className="row">
                        <div className="col-12 h1 fw-600">
                            LIÊN HỆ
                        </div>
                        <div className="col-12 d-none d-lg-block px-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.1918653531893!2d105.83079007612987!3d20.984944780653517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac5e3d90cb01%3A0xe043c814f7cfbaa7!2zU-G7kSAzIFAuIFRy4bqnbiBOZ3V5w6puIMSQw6FuLCBLaHUgxJHDtCB0aOG7iyDEkOG7i25oIEPDtG5nLCBIb8OgbmcgTWFpLCBIw6AgTuG7mWkgMTAwMDAwLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1721720490760!5m2!1sen!2s"
                                width="100%"
                                height="450"
                                style={{ "border": "0" }}
                                // allowFullScreen
                                loading="lazy" />
                        </div>
                        <div className="col-12 px-0">
                            <div className="w-100 row flex-row-reverse contact-form-wrapper mt-3 contact-form-wrapper">
                                <div className="col-md-12 col-lg-6">
                                    <div className="input-group mb-3">
                                        <input placeholder="Họ tên của bạn"
                                            type="text"
                                            name="fullName"
                                            maxLength="200"
                                            className={`form-control border-orange`}
                                            {...register('fullName')}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <input placeholder="Địa chỉ email của bạn"
                                            type="text"
                                            name="email"
                                            maxLength="100"
                                            className={`form-control border-orange`}
                                            {...register('email')}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <input placeholder="Số điện thoại của bạn"
                                            type="text"
                                            name="phone"
                                            maxLength="50"
                                            className={`form-control border-orange`}
                                            {...register('phone')}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <textarea placeholder="Nội dung"
                                            type="text"
                                            name="note"
                                            maxLength={500}
                                            style={{ height: "100px" }}
                                            className={`form-control border-orange`}
                                            {...register('note')}
                                        />
                                    </div>
                                    <button className="btn btn-default bg-dark-orange text-white h5 px-3 w-100px float-right"
                                        onClick={handleSubmit(onsubmit)}
                                    >Gửi</button>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <ContactBlock />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-block d-lg-none mt-3">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.1918653531893!2d105.83079007612987!3d20.984944780653517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac5e3d90cb01%3A0xe043c814f7cfbaa7!2zU-G7kSAzIFAuIFRy4bqnbiBOZ3V5w6puIMSQw6FuLCBLaHUgxJHDtCB0aOG7iyDEkOG7i25oIEPDtG5nLCBIb8OgbmcgTWFpLCBIw6AgTuG7mWkgMTAwMDAwLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1721720490760!5m2!1sen!2s"
                                width="100%"
                                height="450"
                                style={{ "border": "0" }}
                                // allowFullScreen
                                loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ContactBlock = () => {
    let { configs } = useSelector(r => r.systemConfiguration)
    console.log("hahah", configs)
    let { contact } = configs ?? {}

    return (
        <div className='h-100 bg-lighter-orange rounded-10px py-5'>
            <div className='contact-item px-0 border-bottom border-orange py-2 d-flex'>
                <div className="pr-2 d-none d-sm-block">
                    <img src='/assets/images/call-icon.png' className="w-60px" />
                </div>
                <div className="">
                    <div className='h2 text-dark-orange'>Phone</div>
                    {contact?.phone?.split('\n')?.map(p => (<div className='h3 text-black'><a href={`tel:${p}`} className='h3 text-black'>{p}</a></div>))}
                </div>
            </div>

            <div className='contact-item px-2 border-bottom border-orange py-2 mt-2 d-flex'>
                <div className="pr-2 d-none d-sm-block">
                    <img src='/assets/images/call-icon.png' className="w-60px" />
                </div>
                <div className="">
                    <div className='h2 text-dark-orange'>Email</div>
                    <div className='h3 text-black'><a href={`mailto:${contact?.email}`} className='h3 text-black'>{contact?.email}</a></div>
                </div>
            </div>

            <div className='contact-item px-2 py-2 mt-2 d-flex'>
                <div className="pr-2 d-none d-sm-block">
                    <img src='/assets/images/call-icon.png' className="w-60px" />
                </div>
                <div className="" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <div className='h2 text-dark-orange'>Fanpage</div>
                    {/* <div className='h3 text-black'>{contact?.address}</div> */}
                    {contact?.address?.split('\n')?.map(p => (<><a target="_blank" title={p} className="h3 text-black" href={p} rel="noreferrer">{p}</a><br/></>))}
                </div>
            </div>
        </div>
    )
}

export { Contact, ContactBlock }