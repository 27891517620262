import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../actions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { ReactComponent as Show_password_icon } from "../assets/images/show_password.svg";
import { ReactComponent as Hide_password_icon } from "../assets/images/hide_password.svg";
import queryString from 'query-string';
import { history } from "../helpers";
import { useForm } from 'react-hook-form';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleLoginButton } from './GoogleLoginButton';
import { addPopupAction } from '../reducers/popup.reducer';
import { useQuery } from 'react-query';
import { ContactBlock } from '../home';
import { bannerService } from '../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from '@fortawesome/free-solid-svg-icons';

const Login = () => {
   const authentication = useSelector(s => s.authentication)
   const loggedIn = authentication?.loggedIn ?? false

   useEffect(() => {
      if (loggedIn) {
         history.push('/my-courses')
      }
   }, [loggedIn])

   return (
      <section className="w-100">
         {/* <div className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
            <div className="container">
               <div className="row mt-5">
                  <div className="col-md-12 col-lg-8 text-left px-5">
                     <h1 className="text-uppercase mb-1 h1 fw-700">Đăng nhập</h1>
                     <div className="h3 text-uppercase fw-400" >Trang chủ / Đăng nhập</div>
                  </div>
               </div>
            </div>
            <div className='w-100'>
               <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
            </div>
         </div> */}
         <section className="container my-5">
            <div className="w-100 row flex-row-reverse contact-form-wrapper mt-3 contact-form-wrapper">
               <div className="col-md-12 col-lg-6">
                  <LoginForm />
               </div>
               <div className="col-md-12 col-lg-6">
                  <ContactBlock />
               </div>

               <div className='col-12 text-center mt-5'>
                  <div className='h3'>Bạn chưa có tài khoản</div>
                  <div className=''><Link to='/register' className='text-dark-orange h3 fw-600 mt-3'>Đăng ký ngay</Link></div>
               </div>
            </div>
         </section>
      </section>
   )
}

const LoginForm = () => {
   const dispatch = useDispatch()
   const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch, reset } = useForm({
      defaultValues: {
         userName: '',
         password: ''
      }
   });

   const { data: bannersResult, isFetching, isFetched } = useQuery(['banners'], () => { return bannerService.getAll() }, { staleTime: Infinity })
   let banners = bannersResult?.data ?? [];
   banners = banners.filter(r => r.active && r.type == 'popup' && r.event == 'LOGIN')
   let hasBanner = banners.length > 0

   const onSubmit = (e) => {
      let model = {
         userName: e.userName,
         password: e.password
      }

      userActions.login(model.userName, model.password, () => {
         if (hasBanner) {
            dispatch(addPopupAction(banners))
         }
      })
   }

   const loginFacebookClick = (event) => {
   }

   const responseFacebook = (response) => {
      if (response.accessToken) {
         userActions.loginFacebook(response.accessToken);
      } else {
         // alertActions.error("Đăng nhập tài khoản Facebook không thành công");
      }
   }

   const responseGoogle = (response) => {
      userActions.loginGoogle(response.tokenId);
   }

   const failedGoogle = (response) => {
      // alertActions.error("Đăng nhập tài khoản Google không thành công");
   }

   const onLoginFacebookClicked = () => {
      window.FB.login(response => {
         responseFacebook(response.authResponse)
      }, { scope: 'public_profile,email' })
   }

   return (
      <div>
         <div className="row">
            <div className='col-12 px-0'>
               <div className="mt-1 h2">Đăng nhập</div>
               <form className="pb-3 mt-2" onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                  <input className={`form-control form-control-lg rounded bg-transparent border-orange text-orange mt-3 ${errors.userName ? 'is-invalid' : ''}`}
                     type="text"
                     autoComplete='userName'
                     autoCorrect="off"
                     placeholder="Tên tài khoản"
                     {...register('userName', { required: true })} />
                  {/* <input className={`form-control form-control-lg rounded bg-transparent border-orange text-orange mt-3 ${errors.password ? 'is-invalid' : ''}`}
                     type="password"
                     autoComplete='new-password'
                     autoCorrect="off"
                     placeholder="Mật khẩu"
                     {...register('password', { required: true })} /> */}
                  <PasswordInput register={register} errors={errors} />

                  <div className='text-center mt-3'>
                     <Link to='/forgot-password' className='text-dark h5'>Quên mật khẩu?</Link>
                  </div>

                  <button type='submit' className="btn btn-success bg-dark-orange w-100 mt-5 h5 px-0 text-uppercase py-2" onClick={handleSubmit(onSubmit)}>Đăng nhập</button>

                  <div className="w-100 mt-2">
                     <GoogleLoginButton />
                  </div>

                  <div className="w-100 mt-2">
                     <button className="btn btn-primary w-100 text-uppercase py-2 px-0" onClick={onLoginFacebookClicked}>
                        <span className="ml-2 h5">Tiếp tục với Facebook</span>
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div >
   )
}

const PasswordInput = ({ register, errors }) => {
   let [isShow, setIsShow] = useState(false);
   return (
      <div className="input-group mt-3 rounded bg-transparent border border-orange text-orange">
         <input className={`form-control form-control-lg bg-transparent border-0 ${errors.password ? 'is-invalid' : ''}`}
            type={isShow ? 'text' : 'password'}
            maxLength="50"
            autoComplete='new-password'
            autoCorrect="off"
            placeholder="Mật khẩu"
            {...register('password', { required: true })} />
         <span className="input-group-append">
            <button className="btn" onClick={() => setIsShow(!isShow)}>
               {isShow ?
                  <FontAwesomeIcon icon={allIcon.faEyeSlash} /> :
                  <FontAwesomeIcon icon={allIcon.faEye} />
               }
            </button>
         </span>
      </div>
   )
}

export { Login };