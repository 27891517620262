import React, { Component, useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import * as allIconBrand from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { NumberHelpers, UrlHelpers } from "../helpers/index";
import { courseService } from "../services";
import Moment from 'react-moment';
import moment from 'moment';
import { alertActions, learningExcercisesActions, modalActions } from "../actions";
import { connect } from 'react-redux';
import { LearningContext } from './LearningContext';
import { useForceUpdate } from '../helpers/useForceUpdate';
import { useSearchParams } from '../shared/useSearchParams';
import { generatePath, useParams } from "react-router";

const LessonList = React.memo(function LessonList(props) {
    let { chapters, lessons, lessonId } = props;
    return (
        <div className="w-100 h-100 overflow-auto">
            {chapters.map((chapter, chapterIndex) =>
                <ChapterItem currentLessonId={lessonId}
                    chapter={chapter}
                    lessons={lessons.filter(r => r.chapterId == chapter.id)}
                    chapterIndex={chapterIndex}
                    key={chapterIndex}
                />
            )}
        </div>
    )
})

function ChapterItem(props) {
    const { chapter, chapterIndex, lessons, currentLessonId } = props;
    const [expanded, setExpanded] = useState(chapter.expanded);
    useEffect(() => {
        if (lessons.some(r => r.id == currentLessonId)) {
            setExpanded(true);
        }
    }, [currentLessonId]);

    const toggleChapter = () => {
        chapter.expanded = chapter.expanded != true
        setExpanded(chapter.expanded);
    }
    let timeLength = 0;
    timeLength = lessons.map(r => r.timeLength).reduce((a, b) => a + b, 0)
    let completeCount = lessons.filter(r => r.completed).length
    return (
        <div className='chapter-item text-left'>
            <div className='chapter-title px-3 border-bottom border-orange py-2 position-relative hover-pointer bg-lighter-orange' onClick={() => { toggleChapter() }}>
                <div className='h6 text-black mb-0 text-line-2 fw-600' title={chapter.name}>
                    {/* <FontAwesomeIcon icon={expanded ? allIcon.faAngleDown : allIcon.faAngleRight} /> */}
                    <span className=''>{chapter.name}</span>
                </div>
                <div className={`pl-3 h6 text-dark`}>
                    {lessons.length} bài học
                </div>
                <div className='position-absolute right-0 pr-3 top-0 pt-3'>
                    <FontAwesomeIcon icon={expanded ? allIcon.faAngleDown : allIcon.faAngleRight} size='lg' />
                </div>
            </div>
            <div className={`chapter-lessons flex-column ${expanded ? 'd-flex' : 'd-none'}`}>
                {lessons.map(lesson => (
                    <LessonItem key={lesson.id} lesson={lesson}
                        currentLessonId={currentLessonId}
                        chapter={chapter}
                    />
                ))}
            </div>
        </div>
    );
}

function LessonItem({ lesson, currentLessonId, chapter }) {

    let { lessonId, course: courseSlug, courseId } = useParams();
    let searchParams = useSearchParams()

    // const { lesson, currentLessonId, chapter } = props;
    const { completed, locked } = lesson
    const conpletedClicked = () => {
        // if (!lesson.locked)
        //     context.setCompleteLesson(lesson.id)
    }
    const lessonClicked = () => {
        if (lesson.locked) {
            alertActions.error('Bạn cần hoàn thành các bài học trước để mở khóa bài học!!!');
            return
        }
    }

    let tab = searchParams.get('tab') ?? ''

    let lessonLink = UrlHelpers.createLearningUrl(courseSlug, courseId, lesson.slug, lesson.id, tab)
    let active = lessonId == lesson.id
    return (
        <div className="learn-item border-bottom border-orange py-2 px-3 position-relative">
            <div className={`d-flex w-100 ${active ? 'text-orange' : ''}`} >
                <div className="">
                </div>
                <div className="">
                    <Link to={lessonLink}
                        title={lesson.name}
                        className={`stretched-link h6 mb-0 text-line-2 text-decoration-none text-hover-orange ${active ? 'text-orange' : 'text-black '}`}>
                        <FontAwesomeIcon icon={completed ? allIcon.faCheck : allIcon.faDotCircle} size='sm' />
                        <span className='ml-2'>
                            {lesson.name}
                        </span>
                    </Link>
                    <div className={`h6 mb-0 mt-1 ml-3 text-dark ${active && 'Playlist_playing'}`}>
                        {lesson.type == 'video' &&
                            <span>
                                {moment.duration(lesson.timeLength, 'seconds').format("hh:mm:ss")}
                            </span>
                        }

                        {lesson.type == 'pdf' &&
                            <span>
                                {moment.duration(lesson.timeLength, 'seconds').format("mm") + ' phút đọc'}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export { LessonList };
