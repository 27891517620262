import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NumberHelpers } from "../helpers/index";
import * as allIcon from '@fortawesome/free-solid-svg-icons';
import * as allBrandIcon from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
// import ic_course_length from "../assets/images/ic_course_length.png";
// import ic_lessons_count from "../assets/images/ic_lessons_count.png";
// import ic_battery from "../assets/images/ic_battery.png";
import { Preview } from './Preview';
import { CModal, CModalBody } from '@coreui/react';
import ReactPlayer from "react-player/lazy"
let ic_battery = "/assets/images/ic_battery.png";
let ic_course_length = "/assets/images/ic_course_length.png";
let ic_lessons_count = "/assets/images/ic_lessons_count.png";
const PurchaseBadge = ({ course, lessons, isRegistered, registerClicked }) => {

    let [showPreview, setShowPreview] = useState(false)
    const getLessonNumber = () => {
        const totalLessons = lessons.length;
        return totalLessons;
    }

    const getDurationNumber = () => {
        let totalTime = 0
        if (course.type == 'V1')
            totalTime = lessons.reduce((total, lesson) => total + lesson.timeLength, 0);
        else
            totalTime = course.duration ?? 0
        return totalTime;
    }

    const start = moment().add(0 - getDurationNumber(), 's');
    let discount = course.originPrice - course.price;
    return (
        <>
            <div className="d-flex flex-column border border-orange rounded-lg bg-lighter-orange px-5">
                {isRegistered &&
                    <div className="text-center h3 text-dark-orange fw-600 mt-4">Bạn đã đăng ký khóa học này!</div>
                }
                {!isRegistered &&
                    <div className='text-center h2 text-dark-orange fw-600 mt-4'>{course.price > 0 ? `${NumberHelpers.toDefautFormat(course.price)}đ` : 'Thông tin'}</div>
                }

                {(!isRegistered && discount > 0) &&
                    <div className='text-center h5 text-dark fw-600 mt-0'>
                        <del><span className='CourseDetail_discount'>{`${NumberHelpers.toDefautFormat(course.originPrice)} đ`}</span></del>
                    </div>
                }

                <div className='bg-orange mt-2' style={{ height: '1px' }}></div>
                {!course?.introVideo
                    ? <>
                        <div className='text-dark py-2 h5 mt-2'>
                            <FontAwesomeIcon icon={allIcon.faUser} />
                            <span className='ml-1'>Giảng viên</span>
                            <span className='float-right'>{course.authorName ?? 'admin'}</span>
                        </div>

                        <div className='bg-orange mt-2' style={{ height: '1px' }}></div>

                        <div className='text-dark py-2 h5 mt-2'>
                            <FontAwesomeIcon icon={allIcon.faFilm} />
                            <span className='ml-1'>Bài giảng</span>
                            <span className='float-right'>{getLessonNumber()} bài học</span>
                        </div>

                        <div className='bg-orange mt-2' style={{ height: '1px' }}></div>

                        <div className='text-dark py-2 h5 mt-2'>
                            <FontAwesomeIcon icon={allIcon.faFilm} />
                            <span className='ml-1'>Thời lượng</span>
                            <span className='float-right'>{moment.duration(getDurationNumber(), 'seconds').format("hh [giờ] mm [phút]")}</span>
                        </div></>
                    : <>
                        <ReactPlayer
                            url={course?.introVideo}
                            className="react-player absolute top-0 left-0"
                            controls
                            width="100%"
                            height="100%"
                        />
                    </>}


                <div className='bg-orange mt-2' style={{ height: '1px' }}></div>

                {/* <div className='text-dark py-2 h5 mt-2'>
                    <FontAwesomeIcon icon={allIcon.faCheckCircle} />
                    <span className='ml-1'>Đã đăng ký</span>
                    <span className='float-right'>{NumberHelpers.toDefautFormat(course.registered)}</span>
                </div> */}


                <button className="btn btn-success h3 fw-600 text-uppercase py-2 bg-dark-orange text-white rounded-10px mt-5 mb-3" onClick={registerClicked}>
                    {isRegistered ? 'HỌC TIẾP' : 'Đặt mua'}
                </button>


                {/* <ul>
                    <li>
                        <img src={ic_course_length} className='CourseDetail_icon' style={{ width: '16px', height: '16px' }} />
                        <span>Tổng số <strong>{getLessonNumber()}</strong> bài học</span>
                    </li>
                    <li>
                        <img src={ic_lessons_count} className='CourseDetail_icon' style={{ width: '16px', height: '16px' }} />
                        <span>Thời lượng <strong>{moment.duration(getDurationNumber(), 'seconds').format("hh [giờ] mm [phút]")}</strong></span>
                    </li>
                    <li>
                        <img src={ic_battery} className='CourseDetail_icon' style={{ width: '16px', height: '16px' }} />
                        <span>Học mọi lúc, mọi nơi</span>
                    </li>
                </ul> */}
            </div>
        </>
    );
}

const PurchaseBadgeMobile = ({ course, lessons, isRegistered, registerClicked }) => {

    let [showPreview, setShowPreview] = useState(false)
    const getLessonNumber = () => {
        const totalLessons = lessons.length;
        return totalLessons;
    }

    const getDurationNumber = () => {
        const totalTime = lessons.reduce((total, lesson) => total + lesson.timeLength, 0);
        return totalTime;
    }

    const start = moment().add(0 - getDurationNumber(), 's');
    let discount = course.originPrice - course.price;

    return (
        <>
            {showPreview &&
                <CModal show={showPreview} onClose={() => setShowPreview(false)} size='xl'>
                    <CModalBody className='p-0'>
                        <Preview course={course} onClose={() => setShowPreview(false)} url={course.introVideo} />
                    </CModalBody>
                </CModal>
            }
            <div className="w-100 d-flex flex-column align-items-center d-lg-none">
                <div onClick={() => setShowPreview(true)} className="CourseDetail_imgPreview">
                    {course.introVideo != null && course.introVideo != '' &&
                        <>
                            <div className="CourseDetail_iconPlay" style={{ position: 'absolute' }}>
                                <FontAwesomeIcon icon={allBrandIcon.faYoutube} className='fa-3x' />
                            </div>
                            <p>Xem giới thiệu khóa học</p>
                        </>
                    }
                    <div className="CourseDetail_bg" style={{ backgroundImage: `url('${course.thumbnailImage}')` }}></div>
                </div>

                {isRegistered &&
                    <p className="CourseDetail_registered">Bạn đã đăng ký khóa học này!</p>
                }
                {!isRegistered &&
                    <h5 style={{ marginTop: 0 }}>{course.price > 0 ? `${NumberHelpers.toDefautFormat(course.price)} đ` : 'Thông tin'}</h5>
                }

                {(!isRegistered && discount > 0) &&
                    <span className='CourseDetail_discount'>{`${NumberHelpers.toDefautFormat(course.originPrice)} đ`}</span>
                }

                {lessons.length > 0 &&
                    <button className="btn btn-default border-0 rounded-pill px-5 py-2" onClick={registerClicked}>
                        {isRegistered ? 'HỌC TIẾP' : 'đăng ký học'}
                    </button>
                }

                <ul className='mt-3 w-100'>
                    <li className='py-1'>
                        <img src={ic_course_length} className='CourseDetail_icon' style={{ width: '16px', height: '16px' }} />
                        <span className='ml-3'>Tổng số <strong>{getLessonNumber()}</strong> bài học</span>
                    </li>
                    <li className='py-1'>
                        <img src={ic_lessons_count} className='CourseDetail_icon' style={{ width: '16px', height: '16px' }} />
                        <span className='ml-3'>Thời lượng <strong>{moment.duration(getDurationNumber(), 'seconds').format("hh [giờ] mm [phút]")}</strong></span>
                    </li>
                    <li className='py-1'>
                        <img src={ic_battery} className='CourseDetail_icon' style={{ width: '16px', height: '16px' }} />
                        <span className='ml-3'>Học mọi lúc, mọi nơi</span>
                    </li>
                </ul>
            </div>
        </>
    )
}

export { PurchaseBadge, PurchaseBadgeMobile };