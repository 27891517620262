import APICONFIGS from '../api-configs.json';
import CONFIGS from '../configs.json';
import { UrlHelpers, customFetch, authHeader, jsonHeader } from '../helpers';

export const systemService = {
    getConfigs,
};

function getConfigs() {
    let requestOptions = {
        method: 'GET',
        cache: 'no-cache',
        headers: authHeader()
    };
    let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.SystemVariables);
    return customFetch(apiUrl, requestOptions)
        .then(res => res);
}
