import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { NumberHelpers, UrlHelpers, string_isNullOrEmpty } from "../helpers/index";
// import noAvatar from "../assets/images/no-avatar.png";
import { KOCImage } from '../shared/KocImage';
import { Avatar } from '../shared';

let noAvatar = "/assets/images/no-avatar.png";

class CourseGridItem2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseItem: props.data
        };

        this.getThumbImage = this.getThumbImage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            courseItem: nextProps.data
        });
    }

    getThumbImage() {
        var { introVideo, thumbnailImage } = this.state.courseItem;
        return thumbnailImage;
    }

    render() {
        let { courseItem } = this.state;
        const courseDetailPath = UrlHelpers.createCourseDetailUrl(courseItem.slug, courseItem.id);
        let discount = courseItem.originPrice - courseItem.price;
        return (
            <div className='course-item-wrapper mb-3'>
                <div className='thumb'>
                    <img src={courseItem.thumbnailImage} alt={courseItem.name} className='thumb-img' />
                    <div className='thumb-overlay'>
                        <div className='thumb-overlay-tag'>
                            {courseItem.category?.name}
                        </div>
                        <Link to={courseDetailPath} className='tc_preview_course'>Preview Course</Link>
                    </div>
                </div>
                <div className='course-item-detail'>
                    <div className='course-item-detail-wrapper'>
                        <p className='mb-1'>{courseItem.category?.name}</p>
                        <Link to={courseDetailPath}>
                            <h5 className='course-item-title'>
                                {courseItem.name}
                            </h5>
                        </Link>
                        {!string_isNullOrEmpty(courseItem.authorName) &&
                            <p className='mb-1'><em>Tác giả: {courseItem.authorName}</em></p>
                        }
                        <p className='course-item-description' dangerouslySetInnerHTML={{ __html: courseItem.description }}>
                        </p>
                    </div>
                </div>
                <div className='course-item-footer'>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={allIcon.faUserAlt} />
                        </li>
                        <li>
                            {courseItem.registered}
                        </li>
                        {courseItem.totalRate > 0 &&
                            <li><FontAwesomeIcon icon={allIcon.faStar} style={{ color: 'gold' }} /> {Math.round(courseItem.totalStar / courseItem.totalRate * 10) / 10} ({NumberHelpers.toDefautFormat(courseItem.totalRate)} đánh giá)</li>
                        }
                    </ul>
                    <div>
                    </div>
                </div>
            </div>
        );
    }
}

const CourseGridItem = ({ course }) => {
    const courseDetailPath = UrlHelpers.createCourseDetailUrl(course.slug, course.id);

    let avgStar = course.totalRate == 0 ? 0 : Math.round(course.totalStar / course.totalRate * 10) / 10

    return (
        <div className='w-100 h-100 px-3 py-3 border border-orange rounded-15px hover-shadow-orange'>
            <div className='course-wrapper h-100 overflow-hidden d-flex flex-column'>
                <div className='course-image position-relative' style={{ paddingTop: '50%' }}>
                    <div className="position-absolute top-0 left-0 w-100 h-100">
                        <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' src={course.thumbnailImage} />
                    </div>
                </div>
                <div className="course-info pt-2 pb-2 flex-grow-1">
                    <div className="course-author d-flex align-items-center">
                        <Avatar src='' className='w-60px h-60px' />
                        <div className="ml-2">
                            <span className="bg-light-pink px-3 py-1 rounded-lg text-orange text-line-1">{course.category?.name}</span>
                            <span className="h6 fw-600 text-dark">
                                {course.authorName ?? 'admin'}
                            </span>
                        </div>
                    </div>
                    <div className="course-title text-o-ellipsis text-line-2 mt-2">
                        {course?.landingpageUrl ?
                            <a href={course?.landingpageUrl} className='text-black stretched-link'><span className="h4">{course.name}</span></a> :
                            <Link to={courseDetailPath} className='text-black stretched-link'>
                                <span className="h4">{course.name}</span>
                            </Link>
                        }
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-between mt-2">
                        <div className="h5">
                            <FontAwesomeIcon icon={allIcon.faUser} className='text-orange' />
                            <span className="fw-600 text-dark ml-1">{NumberHelpers.toDefautFormat(course.registered)}</span>
                        </div>

                        <div className="h5">
                            <span className="fw-600 text-dark mr-1">{avgStar}</span>
                            <FontAwesomeIcon icon={allIcon.faStar} className='text-orange' />
                        </div>
                    </div>

                    <div className="mt-2 px-2 rounded-lg bg-light-pink text-dark-orange h4 py-2 fw-700">
                        <span>{!course.price ? 'Liên hệ' : <>{NumberHelpers.toDefautFormat(course.price)}<small>đ</small></>}
                            {course.originPrice > course.price &&
                                <del className="h5 text-dark pl-1">{NumberHelpers.toDefautFormat(course.originPrice)}</del>
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CourseGridItem, CourseGridItem2 };
