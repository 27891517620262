import { createSlice } from '@reduxjs/toolkit'

const initialState = () => { return { configs: null }; }

export const systemConfiguration = createSlice({
  name: 'systemConfiguration',
  initialState: initialState(),
  reducers: {
    systemConfigurationSuccessAction: (state, action) => {
      state['configs'] = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { systemConfigurationSuccessAction, } = systemConfiguration.actions

export default systemConfiguration.reducer
