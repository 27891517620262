import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { courseService } from "../services";
import { NumberHelpers, UrlHelpers } from "../helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as allIcon from "@fortawesome/free-solid-svg-icons";
import { KOCImage } from "../shared/KocImage";
import { Avatar } from "../shared";
import { useSelector } from "react-redux";

const HomeFeatured = (props) => {
    let { myCourses } = props;
    let userCourses = myCourses?.courses;

    let { configs } = useSelector(s => s.systemConfiguration)
    let general = configs?.general
    let showCoursePrice = general?.showCoursePrice ?? true
    let numberCourses = general?.numberCourses ?? 6
    let courseType = general?.courseType ?? 'featured'

    let [courses, setCourses] = useState([]);

    useEffect(() => {
        getCourses()

    }, []);

    const getCourses = () => {
        let pagingModel = { page: 1, pageSize: numberCourses }
        let filtersModel = [{ field: 'active', value: 'true', operator: '==' }];
        let sortModel = { sort: 'createdDateTime', direction: 'asc' }

        if (courseType == 'top-register') {
            sortModel = { sort: 'registered', direction: 'desc' }
        } else if (courseType == 'top-rate') {
            sortModel = { sort: 'totalRate', direction: 'desc' }
        } else if (courseType == 'newest') {
            sortModel = { sort: 'createdDateTime', direction: 'desc' }
        } else {
            filtersModel.push({ field: 'featured', value: 'true', operator: '==' })
        }

        let promise = courseService.filter(pagingModel, filtersModel, sortModel)

        promise.then(res => {
            if (!res.isSuccess) return
            let courses = res.data.items;
            if (userCourses) {
                courses.forEach(element => {
                    let userCourse = userCourses.find(item => item.course.id == element.id);
                    element.isRegistered = userCourse != null;
                    element.progress = userCourse?.userCourse.progress;
                });
            }
            setCourses(courses);
        });
    }

    return (
        <section>
            <section className="container">
                <div className="row">
                    <div className="col-12 mt-4 text-center">
                        <div className="h2 fw-700">Khóa học nổi bật</div>
                    </div>
                </div>
                <div className="row mt-4">
                    {courses.map(item =>
                        <div key={item.id} className="col-sm-12 col-md-6 col-lg-4 mb-3">
                            <FeaturedCourse course={item} showPrice={showCoursePrice} />
                        </div>
                    )}
                </div>
                <div className="row justify-content-center mt-3">
                    <Link to={'/courses'} className='btn btn-success h3 mt-4 fw-700 px-5 py-2'>
                        XEM TOÀN BỘ KHÓA HỌC
                    </Link>
                </div>
            </section>
        </section>
    )
}

const FeaturedCourse = ({ course, showPrice }) => {
    const courseDetailPath = UrlHelpers.createCourseDetailUrl(course.slug, course.id);
    return (
        <div className='w-100 h-100 px-3 py-3 border border-orange rounded-15px hover-shadow-orange'>
            <div className='course-wrapper h-100 overflow-hidden d-flex flex-column'>
                <div className='course-image position-relative' style={{ paddingTop: '50%' }}>
                    <div className="position-absolute top-0 left-0 w-100 h-100">
                        <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' src={course.thumbnailImage} />
                    </div>
                </div>
                <div className="course-info pt-2 pb-2 flex-grow-1">
                    <div className="course-author d-flex align-items-center">
                        <Avatar src='' className='w-60px h-60px' />
                        <div className="ml-2">
                            <span className="bg-light-pink px-3 py-1 rounded-lg text-orange">{course.category?.name}</span><br />
                            <span className="h6 fw-600 text-dark">
                                {course.authorName ?? 'admin'}
                            </span>
                        </div>
                    </div>
                    <div className="course-title text-o-ellipsis text-line-2 mt-2">
                        <Link to={courseDetailPath} className='text-black stretched-link'>
                            <span className="h4">{course.name}</span>
                        </Link>
                    </div>

                </div>
                <div>
                    <div className="d-flex justify-content-between mt-2">
                        <div className="h5">
                            <FontAwesomeIcon icon={allIcon.faUser} className='text-orange' />
                            <span className="fw-600 text-dark ml-1">{NumberHelpers.toDefautFormat(course.registered)}</span>
                        </div>

                        <div className="h5">
                            <span className="fw-600 text-dark mr-1">{course?.totalStar && course.totalRate ? Math.round(course.totalStar / course.totalRate * 10) / 10: 0}</span>
                            <FontAwesomeIcon icon={allIcon.faStar} className='text-orange' />
                        </div>
                    </div>
                    {showPrice &&
                        <div className="mt-2 px-2 rounded-lg bg-light-pink text-dark-orange h4 py-2 fw-700">
                            <span>{!course.price ? 'Liên hệ' : <>{NumberHelpers.toDefautFormat(course.price)}<small>đ</small></>}
                                {course.originPrice > course.price &&
                                    <del className="h5 text-dark pl-1">{NumberHelpers.toDefautFormat(course.originPrice)}</del>
                                }
                            </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export { HomeFeatured };