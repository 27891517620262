import { useState, useEffect, useRef } from "react";
import { CourseGridItem } from "../courses";
import { Link } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import { isMobile } from "react-device-detect";

const HomeCallToAction = (props) => {
    let authentication = useSelector(r => r.authentication)
    let { loggedIn } = authentication;

    return (
        <section className="container-fluid py-5">
            <div className="row text-center">
                <div className="col-12 mt-4 ">
                    <div className="h1 fw-700 text-uppercase text-dark-orange">37.402+ người khác đã học; Còn bạn?</div>
                    <p className="h4 text-dark mt-4">Kiến thức là một thứ mà bạn sẽ có được bằng cách cho đi - Ivan Sutherland</p>
                    <Link to={loggedIn ? '/courses' : '/login'} className='btn btn-success h3 mt-4 fw-700 px-5 py-2'>
                        {loggedIn ? 'Học ngay' : 'Tham gia ngay'}
                    </Link>
                </div>
            </div>
        </section>
    )
}
export { HomeCallToAction };