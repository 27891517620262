import React, { Component } from 'react';
import { useState } from 'react';
import { Link, Switch, Route } from "react-router-dom";
import { alertActions } from '../actions';
import configs from "../configs.json";
import { userService } from '../services';
let logo = "/assets/images/logo(300x95).png";
export default class Footer extends Component {

    constructor(props) {
        super(props)
    }

    //function which is called the first time the component loads
    componentDidMount() {
    }

    render() {
        return (
            <AppFooter />
        )
    }

}

function AppFooter(props) {
    let titleClass = 'h4 text-black fw-600'
    let linkClass = 'h6 text-dark text-hover-orange'

    let [email, setEmail] = useState('')
    const onSubmit = () => {
        if (!validateEmail(email.trim())) {
            alertActions.error('Vui lòng nhập đúng email của bạn.')
            return
        }

        userService.subscribe(email).then(res => {
            if (res.isSuccess) {
                setEmail('')
                alertActions.success('Đăng ký nhận tin thành công.');
            }
        })
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <div className="Footer_wrapper bg-pink py-5">
            <section className="container Footer_container">
                <section className="row">
                    <section className="col-12 col-md-12 col-lg-3 Footer_column">
                        <div className="Footer_footerLogo">
                            <Link to="/"><img src='/assets/images/logo(177x50).png' alt="Tambooks Logo" className='h-60px mb-3' /></Link>
                            <p className='Footer_caption h6'>{configs.caption}</p>
                            {/* <p>Tambooks hướng đến mục tiêu tất cả các khóa đều là Học trước trả tiền sau với mức học phí tùy tâm.</p> */}
                            {/* <br /> */}
                            <p className='h6'><b>Hotline:</b> <a href="tel:0935488666"> 0935488666</a> / <a href="tel:0904090633"> 0904090633</a></p>
                            <p className='h6'><b>Địa chỉ:</b> Số 3 P. Trần Nguyên Đán, Khu đô thị Định Công, Hoàng Mai, Hà Nội</p>
                            {/* <p className='h6'><b>Phone:</b> +8617110621457</p>
                            <p className='h6'><b>Company:</b> Guangzhou Shang Shun Trading CO.,Ltd</p>
                            <p className='h6'><b>Address:</b> Room 201, No. 103, Sanwenyong East Street, Dawen Village, Dongyong Town, Nansha District, Guangzhou 248</p> */}
                        </div>
                    </section>
                    <section className="col-12 col-sm-6 col-md-3 col-lg-2 Footer_column">
                        <div className={titleClass}>Tambooks</div>
                        <ul className='list-unstyled'>
                            <li className='py-1'><Link className={linkClass} to="/about-us">Về chúng tôi</Link></li>
                            <li className='py-1'><Link className={linkClass} to="/contact">Liên hệ</Link></li>
                            <li className='py-1'><Link className={linkClass} to="/teachers">Giảng viên</Link></li>
                        </ul>
                    </section>
                    <section className="col-12 col-sm-6 col-md-3 col-lg-2 Footer_column">
                        <div className={titleClass}>Liên kết</div>
                        <ul className='list-unstyled'>
                            <li className='py-1'><a className={linkClass} target="_blank" href="https://9talk.edu.vn/">9talk</a></li>
                            <li className='py-1'><a className={linkClass} target="_blank" href="https://www.facebook.com/nguyenhieutambooksyoga/?ref=pages_you_manage">FanPage</a></li>
                            <li className='py-1'><a className={linkClass} target="_blank" href="https://www.tiktok.com/@tambooks_yoga?lang=en">Tiktok</a></li>
                            <li className='py-1'><a className={linkClass} target="_blank" href="https://www.youtube.com/@tambooksvn/featured">Youtube</a></li>
                        </ul>
                    </section>
                    <section className="col-12 col-sm-6 col-md-3 col-lg-2 Footer_column">
                        <div className={titleClass}>Hỗ trợ</div>
                        <ul className='list-unstyled'>
                            <li className='py-1'><Link className={linkClass} to="/terms">Điều khoản sử dụng dịch vụ</Link></li>
                            <li className='py-1'><Link className={linkClass} to="/secure-policy">Chính sách bảo mật</Link></li>
                            <li className='py-1'><Link className={linkClass} to="/refund-policy">Chính sách hoàn tiền</Link></li>
                        </ul>
                    </section>
                    <section className="col-12 col-sm-6 col-md-3 col-lg-3 Footer_footerSubscribe Footer_column">
                        <div className={titleClass}>Theo dõi chúng tôi</div>
                        <p className='text-dark h6'>Đăng ký nhận những thông tin hữu ích về học tập từ Tambooks</p>
                        <input type="text"
                            placeholder="Email của bạn..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='form-control rounded-pill' />
                        <div className="btn btn-success rounded-pill px-4 text-white fw-600 mt-2 h5 bg-orange"
                            onClick={onSubmit} >Đăng ký</div>
                    </section>
                </section>
            </section>
        </div>
    )
}